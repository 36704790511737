import * as React from "react"
import Svg, { Defs, ClipPath, Path, Mask, G, Use } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={"100%"}
    height={"100%"}
    viewBox="0 0 429 349"
    {...props}
  >
    <Defs>
      <ClipPath id="b">
        <Path d="M0 0h429v349H0z" />
      </ClipPath>
      <ClipPath id="c">
        <Path d="M0 0h429v349H0z" />
      </ClipPath>
      <ClipPath id="d">
        <Path d="M0 0h429v349H0z" />
      </ClipPath>
      <Mask id="f">
        <G filter="url(#a)">
          <Path
            style={{
              fill: "#000",
              fillOpacity: 0.501961,
              stroke: "none",
            }}
            d="M0 0h429v349H0z"
          />
        </G>
      </Mask>
      <Mask id="h">
        <G filter="url(#a)">
          <Path
            style={{
              fill: "#000",
              fillOpacity: 0.501961,
              stroke: "none",
            }}
            d="M0 0h429v349H0z"
          />
        </G>
      </Mask>
      <Mask id="j">
        <G filter="url(#a)">
          <Path
            style={{
              fill: "#000",
              fillOpacity: 0.501961,
              stroke: "none",
            }}
            d="M0 0h429v349H0z"
          />
        </G>
      </Mask>
      <G id="e" clipPath="url(#b)">
        <Path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "#fff",
            fillOpacity: 1,
          }}
          d="M264.898 182.156h85.762c13.598 0 24.621 11.028 24.621 24.63v31.01c0 13.602-11.023 24.63-24.62 24.63h-85.763c-13.597 0-24.62-11.028-24.62-24.63v-31.01c0-13.602 11.023-24.63 24.62-24.63Zm0 0"
        />
      </G>
      <G id="g" clipPath="url(#c)">
        <Path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "#fff",
            fillOpacity: 1,
          }}
          d="M105.04 260.445h85.76c13.598 0 24.622 11.024 24.622 24.625v31.016c0 13.601-11.024 24.625-24.621 24.625h-85.762c-13.598 0-24.621-11.024-24.621-24.625V285.07c0-13.601 11.023-24.625 24.621-24.625Zm0 0"
        />
      </G>
      <G id="i" clipPath="url(#d)">
        <Path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "#fff",
            fillOpacity: 1,
          }}
          d="M264.598 80.848h85.765c13.598 0 24.621 11.027 24.621 24.629v31.015c0 13.602-11.023 24.625-24.62 24.625h-85.766c-13.598 0-24.618-11.023-24.618-24.625v-31.015c0-13.602 11.02-24.63 24.618-24.63Zm0 0"
        />
      </G>
    </Defs>
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#f2f2f2",
        fillOpacity: 1,
      }}
      d="M429 34.934v279.132C429 333.363 411.473 349 389.848 349H39.152C17.527 349 0 333.363 0 314.066V34.934C0 15.637 17.527 0 39.152 0h350.696C411.473 0 429 15.637 429 34.934Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="m143.066 348.078 25.098-5.441 29.086-6.313s1.574 4.82 3.785 11.754Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="M206.602 344.953s-.235 1.258-.715 3.125H88.707c-1.293-3.937-1.871-7.992-.332-11.34.262-.586.586-1.14.977-1.648.289-.399.609-.774.964-1.117 2.301-2.278 3.711-7.192 4.559-12.786a149.088 149.088 0 0 0 1.258-23.085c.012-.012.012-.012 0-.012-.024-1.824-.07-2.914-.07-2.914s-.45-6.738-.954-16.918c.012 0 0-.012 0-.012-1.386-27.469-3.265-80.055 1.61-93.996a6.578 6.578 0 0 1 1.82-3.078 132.527 132.527 0 0 1 20.871-12.332c.422-.188.649-.293.649-.293l16.086-.305 19.976-3.527 13.879-2.54s1.02.071 2.621.294c5.309.73 16.988 3.117 18.637 10.027 0 .012.012.024.012.035.207.906.242 1.844.105 2.762-1.574 11.52 9.54 82.27 12.926 85.805 3.383 3.539 2.492 16.468-5.875 24.683a12.79 12.79 0 0 0-3.13 5.418c-3.16 10.168 1.802 26.778 6.653 34.547a32.164 32.164 0 0 1 4.653 19.207Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#a0616a",
        fillOpacity: 1,
      }}
      d="m270.598 118.777-.336-12.3-.453-16.497-13.145-.605-1.84 16.387-1.715 15.23Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="m135.945 170.855 18.442 19.797s78.14 12.625 78.664 5.996c.523-6.628-4.625-8.546 3.812-7.96 8.438.59 10.465 6.585 11.754.851 1.285-5.734-5.789-5.875 2.403-6.687 8.19-.813 26.082-72.352 26.082-72.352l-28.54 4.605s-5.546 34.59-9.027 39.008c-3.476 4.422-6.969 7.594-9.113 7.785-2.145.192-94.477 8.957-94.477 8.957Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#a0616a",
        fillOpacity: 1,
      }}
      d="M173.613 118.16c0 14.969-12.129 27.102-27.093 27.102-14.961 0-27.09-12.133-27.09-27.102 0-14.965 12.129-27.097 27.09-27.097 14.964 0 27.093 12.132 27.093 27.097Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="m125.41 141.5 10.738 2.059s-9.988-16.13-9.398-23.508c.586-7.383 8.625-10.028 8.625-10.028s4.453.965 9.14 1.504a23.403 23.403 0 0 1 2.176-4.523 7.884 7.884 0 0 0 2.301 4.863c1.719.149 3.45-.05 5.09-.586 1.856-.847 1.82-2.316 1.211-3.691 8.941.433 17.414 4.785 17.414 4.785.27-3.61.133-7.238-.41-10.816-.262-.07-.52-.153-.777-.235a24.118 24.118 0 0 1-4.606-2.008 7.968 7.968 0 0 0 4.348-2.046c-.989-2.891-2.563-5.395-5.043-6.618a18.573 18.573 0 0 0-15.43-.41s5.746-4.199 3.633-5.914c-.14.14-.281.27-.434.399-.199.164-.425.343-.636.507-1.305 1.059-3.254-.437-3.512-2.035a5.21 5.21 0 0 0-.117-.597 5.842 5.842 0 0 0-.13-.485 4.852 4.852 0 0 0-.144-.398c-3.91-.942-15.746 2.879-20.293 11.297 0 0-31.785 15.28-3.746 48.484Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#a0616a",
        fillOpacity: 1,
      }}
      d="M112.418 348.078h-15.18l-.52-.855-7.366-12.133-4.067-6.7 9.59-7.202 4.512-3.387 9.953 23.12Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#00bfa6",
        fillOpacity: 1,
      }}
      d="m119.414 168.84 37.887 87.558 15.324-93.93c-1.598-.222-2.621-.292-2.621-.292l-6.524-12.672-37.355 1.941-6.063 17.102s-.222.105-.648.293ZM169.258 348.078h-29.836l19.05-54.066 9.692 48.625Zm0 0"
    />
    <Use xlinkHref="#e" mask="url(#f)" />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M350.66 263.348h-85.762c-14.101-.02-25.527-11.446-25.543-25.551v-31.012c.016-14.105 11.442-25.531 25.543-25.547h85.762c14.098.016 25.524 11.442 25.543 25.547v31.012c-.02 14.105-11.445 25.531-25.543 25.55Zm-85.762-80.27c-13.086.016-23.687 10.621-23.699 23.707v31.012c.012 13.086 10.617 23.691 23.7 23.707h85.761c13.082-.016 23.684-10.621 23.7-23.707v-31.012c-.016-13.086-10.618-23.691-23.7-23.707Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#a0616a",
        fillOpacity: 1,
      }}
      d="M327.332 225.363c0 10.864-8.805 19.668-19.664 19.668-10.86 0-19.664-8.804-19.664-19.668 0-10.863 8.805-19.668 19.664-19.668 10.86 0 19.664 8.805 19.664 19.668Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M311.98 244.922c-2.414-5.387 10.665-16.559 2.286-17.125 0 0-5.512-9.66-9.008-7.07-4.051 3-9.953-2.45-13.422-6.094a11.449 11.449 0 0 0 1.973-2.035c-1.102.203-2.22.32-3.34.351a5.71 5.71 0 0 1-.856-2.035 3.462 3.462 0 0 1 1.453-3.68c1.27-.71 2.895-.277 4.25-.785.344-.168.586-.496.641-.875.207-.887.8-1.637 1.617-2.047a3.043 3.043 0 0 1 2.606-.062c.156.086.332.137.515.14.946-.058 1.883-.23 2.79-.515a21.593 21.593 0 0 1-3.5-2.047 7.547 7.547 0 0 1 6.105-1.574 22.26 22.26 0 0 1 6.414 2.316c1.187.582 2.375 1.16 3.558 1.738a39.442 39.442 0 0 1 7.25 4.153 20.414 20.414 0 0 1 7.786 15.531c.105 5.992-5.016 22.406-19.118 21.715Zm0 0"
    />
    <Use xlinkHref="#g" mask="url(#h)" />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M190.8 341.633h-85.76c-14.103-.016-25.528-11.445-25.544-25.547V285.07c.016-14.101 11.442-25.53 25.543-25.547h85.762c14.097.016 25.523 11.446 25.539 25.547v31.016c-.016 14.101-11.442 25.531-25.54 25.547Zm-85.76-80.27c-13.087.016-23.688 10.621-23.704 23.707v31.016c.016 13.086 10.617 23.691 23.703 23.707h85.762c13.082-.016 23.683-10.621 23.699-23.707V285.07c-.016-13.086-10.617-23.691-23.7-23.707Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb6b6",
        fillOpacity: 1,
      }}
      d="M169.215 306.191c0 11.04-8.945 19.985-19.98 19.985-11.032 0-19.977-8.946-19.977-19.985 0-11.035 8.945-19.984 19.976-19.984 11.036 0 19.98 8.95 19.98 19.984Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M136.219 311.328a1.835 1.835 0 0 0-1.512.156c-.457.27-.773.723-.871 1.243a3.92 3.92 0 0 0 .867 2.98c.68.816 1.293 1.691 1.824 2.613.536.95.465 2.121-.172 3.008a2.506 2.506 0 0 1-2.625.55 5.555 5.555 0 0 1-2.277-1.644c-3.82-4.144-7.805-11.027-5.851-16.312.16-.567.226-1.156.191-1.742.035-.672.16-1.688-.473-1.914-1.77-.641 3.192-10.977 10.852-13.227-.375-3.531 18.047-8.61 17.043-4.176-.184.797 1.597.364 2.41.407 6.25.34 13.313 1.91 12.742 9.156-.222 2.804.938 2.16 1.871 4.812-1.453.867-1.925 2.766-3.047 1.5-1.117-1.27-2.074-2.855-3.68-3.386-1.706-.567-3.558.28-5.015 1.34-1.457 1.054-2.793 2.367-4.504 2.921-1.922.621-4.015.188-6.015.438a11.915 11.915 0 0 0-7.258 4.265 35.294 35.294 0 0 0-4.504 7.305"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="m124.984 180.953-27.027 1.176s-39.953 68.172-35.25 72.875c4.7 4.703 9.5 2.031 3.574 8.07-5.926 6.035-11.8 3.684-8.277 8.387 3.527 4.7 8.226-.59 3.527 6.168-4.703 6.762 33.489 70.45 33.489 70.45l16.453-8.227s-16.012-42.079-17.114-47.594c-1.101-5.516-1.246-10.23 0-11.988 1.25-1.758 30.625-99.317 30.625-99.317Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#00bfa6",
        fillOpacity: 1,
      }}
      d="M269.734 196.043c0 3.5-2.836 6.336-6.336 6.336a6.335 6.335 0 0 1-6.336-6.336 6.336 6.336 0 1 1 12.672 0Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M262.535 199.34a.705.705 0 0 1-.562-.281l-1.73-2.305a.703.703 0 0 1-.087-.703.701.701 0 0 1 .567-.426.712.712 0 0 1 .648.281l1.129 1.508 2.906-4.36a.706.706 0 0 1 1.172.785l-3.457 5.188a.709.709 0 0 1-.566.313Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#00bfa6",
        fillOpacity: 1,
      }}
      d="M115.074 275.234c0 3.5-2.836 6.34-6.336 6.34-3.5 0-6.336-2.84-6.336-6.34s2.836-6.34 6.336-6.34c3.5 0 6.336 2.84 6.336 6.34Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M107.875 278.531a.707.707 0 0 1-.566-.281l-1.727-2.305a.712.712 0 0 1-.09-.703.72.72 0 0 1 .567-.426c.254-.027.5.079.652.282l1.129 1.511 2.906-4.359a.704.704 0 1 1 1.172.781l-3.457 5.188a.7.7 0 0 1-.566.312Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#e6e6e6",
        fillOpacity: 1,
      }}
      d="M354.54 205.762a6.257 6.257 0 0 1-6.259-6.258 6.26 6.26 0 0 1 6.258-6.258 6.26 6.26 0 0 1 6.258 6.258c0 3.457-2.8 6.258-6.258 6.258Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="m356.723 200.816-1.313-1.312 1.313-1.309a.615.615 0 0 0 .16-.597.612.612 0 0 0-.438-.438.608.608 0 0 0-.597.164l-1.309 1.309-1.309-1.309a.613.613 0 0 0-.87 0 .617.617 0 0 0-.005.871l1.313 1.309-1.313 1.312a.619.619 0 0 0 .277 1.035.626.626 0 0 0 .598-.163l1.31-1.31 1.308 1.31a.615.615 0 0 0 .597.16.617.617 0 0 0 .277-1.031Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#e6e6e6",
        fillOpacity: 1,
      }}
      d="M192.512 281.273a6.258 6.258 0 0 1 0-12.515 6.26 6.26 0 0 1 0 12.516Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="m194.695 276.324-1.308-1.308 1.308-1.309a.617.617 0 0 0-.004-.871.612.612 0 0 0-.867-.004l-1.312 1.309-1.309-1.309a.62.62 0 0 0-.871 0 .621.621 0 0 0 0 .875l1.309 1.309-1.309 1.308a.626.626 0 0 0-.164.598.619.619 0 0 0 .437.437.608.608 0 0 0 .598-.164l1.309-1.308 1.312 1.308a.613.613 0 0 0 .871 0 .62.62 0 0 0 0-.87Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#a0616a",
        fillOpacity: 1,
      }}
      d="M274.34 83.797c0 6.101-4.945 11.05-11.047 11.05s-11.047-4.949-11.047-11.05c0-6.102 4.945-11.05 11.047-11.05s11.047 4.948 11.047 11.05Zm0 0"
    />
    <Use xlinkHref="#i" mask="url(#j)" />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M350.363 162.04h-85.765c-14.098-.017-25.524-11.446-25.54-25.548v-31.015c.016-14.102 11.442-25.532 25.54-25.547h85.765c14.098.015 25.524 11.445 25.54 25.547v31.015c-.016 14.102-11.442 25.531-25.54 25.547Zm-85.765-80.27c-13.082.015-23.684 10.62-23.7 23.707v31.015c.016 13.086 10.618 23.692 23.7 23.703h85.765c13.082-.011 23.684-10.617 23.7-23.703v-31.015c-.016-13.086-10.618-23.692-23.7-23.707Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M333.105 123.29c.055 5.151 1.696 15.882.52 20.933l-.191-.426a22.61 22.61 0 0 1-3.102 2.078 14.917 14.917 0 0 0-3.45-5.07 17.871 17.871 0 0 1-.823 7.015c-4.399 1.582-12.122-3.41-16.91-3.515-7.7-.176-15.204-2.09-22.56-4.118-2.612-.718-5.484-1.656-6.667-3.824-1.18-2.168.933-4.508.797-6.957-.985-17.43 7.312-20.351 15.21-25.008 1.739-1.027 2.59-3.234 4.555-4.093 2.727-1.188 5.97-.618 8.934-.02a4.51 4.51 0 0 1-2.984-3.265 4.074 4.074 0 0 1 1.59-3.946 7.334 7.334 0 0 1 6.152-.879 20.573 20.573 0 0 1 5.644 2.774 11.174 11.174 0 0 1 3.098 2.508c.844.98.992 2.382.367 3.52a4.03 4.03 0 0 1-3.148 1.526c-.93.028-1.864-.07-2.774-.289a21.107 21.107 0 0 1 10.75 6.508c3.528 4.106 4.93 9.39 4.992 14.547Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb6b6",
        fillOpacity: 1,
      }}
      d="M322.547 123c0 9.426-7.64 17.07-17.067 17.07-9.425 0-17.066-7.644-17.066-17.07 0-9.43 7.64-17.07 17.066-17.07 9.426 0 17.067 7.64 17.067 17.07Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M324.102 120.422c-2.008 2.43-4.993 2.305-7.645 1.914a62.187 62.187 0 0 1-2.512-.406 16.226 16.226 0 0 0-.507-1.403 14.917 14.917 0 0 0-3.45-5.07 17.565 17.565 0 0 1-.43 5.586 88.143 88.143 0 0 1-4.644-1.207 5.384 5.384 0 0 1-4.492-5.914c-2.219 1.691-4.633 3.156-7.168 3.183a6.68 6.68 0 0 1-6.262-4.878 16.116 16.116 0 0 1-.305-6.829c.34-2.71 1.098-5.691 2.93-6.78 1.469-.888 3.168-.255 4.68.464a53.778 53.778 0 0 1 9.433 5.785l.036.742c.761-3.64 3.273-6.453 6.011-6.734 1.844-.18 3.645.672 5.383 1.531 2.274 1 4.387 2.332 6.274 3.95a13.979 13.979 0 0 1 4.238 7.3c.652 3.047.16 6.668-1.57 8.766Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M307.207 96.73c.176.016-.254-.082-.113-.023a1.625 1.625 0 0 1-.2-.11c-.007-.007-.148-.12-.058-.042a1.396 1.396 0 0 1-.453-.633 5.772 5.772 0 0 1-.266-1.879 5.974 5.974 0 0 0-.418-2.453 1.465 1.465 0 0 0-2.304-.512 3.392 3.392 0 0 0-.665 2.277c-.043.79.04 1.579.247 2.34a6.738 6.738 0 0 0 2.054 3.227 6.994 6.994 0 0 0 4.067 1.715 1.067 1.067 0 0 0 .746-1.8l-1.89-1.794a1.054 1.054 0 0 0-1.49.004c-.41.41-.41 1.074-.003 1.488l1.89 1.793.747-1.8a4.558 4.558 0 0 1-.918-.188 3.565 3.565 0 0 1-.918-.403 4.29 4.29 0 0 1-.778-.546 5.68 5.68 0 0 1-.441-.426c-.047-.055-.098-.11-.145-.168 0 .004-.199-.25-.113-.133a5.695 5.695 0 0 1-.566-.95 2.217 2.217 0 0 1-.14-.366 5.784 5.784 0 0 1-.165-.633 5.47 5.47 0 0 1-.07-1.36c.008-.195.027-.386.054-.578.028-.097.047-.203.063-.304 0-.258-.035.136-.172.195l-.531.144a.564.564 0 0 1-.434-.253c-.023-.086-.015-.07.02.046.039.141.07.286.09.426.043.336.066.676.07 1.012.008.715.09 1.426.25 2.125a3.4 3.4 0 0 0 2.953 2.676 1.066 1.066 0 0 0 1.059-1.055 1.083 1.083 0 0 0-1.059-1.059Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#00bfa6",
        fillOpacity: 1,
      }}
      d="M271.578 94.75a6.338 6.338 0 1 1-12.675-.005 6.338 6.338 0 0 1 12.675.005Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M264.375 98.047a.71.71 0 0 1-.563-.281l-1.73-2.305a.707.707 0 0 1 .48-1.129c.25-.027.5.078.649.281l1.133 1.508 2.902-4.355a.71.71 0 0 1 .98-.2c.32.22.41.657.196.98l-3.461 5.188a.709.709 0 0 1-.566.313Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#e6e6e6",
        fillOpacity: 1,
      }}
      d="M351.777 101.707a6.257 6.257 0 0 1-6.257-6.258c0-3.457 2.8-6.258 6.257-6.258a6.26 6.26 0 0 1 6.258 6.258 6.26 6.26 0 0 1-6.258 6.258Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="m353.96 96.758-1.312-1.309 1.313-1.308a.615.615 0 0 0 .16-.598.606.606 0 0 0-.437-.438.608.608 0 0 0-.598.165l-1.309 1.308-1.308-1.308a.613.613 0 0 0-.871 0 .617.617 0 0 0-.004.87l1.312 1.31-1.312 1.308a.617.617 0 0 0-.16.597.617.617 0 0 0 .437.442.626.626 0 0 0 .598-.164l1.308-1.309 1.309 1.309a.615.615 0 0 0 .598.16.612.612 0 0 0 .437-.438.627.627 0 0 0-.16-.597ZM394.352 42.36H24.52a.924.924 0 0 1-.922-.922c0-.508.414-.922.922-.922h369.832a.921.921 0 1 1 0 1.843ZM73.078 20.352c0-4.02-3.183-7.278-7.113-7.278-3.93 0-7.113 3.258-7.113 7.278 0 4.015 3.183 7.273 7.113 7.273 3.93 0 7.113-3.258 7.113-7.273ZM97.656 20.352c0-4.02-3.183-7.278-7.113-7.278-3.93 0-7.117 3.258-7.117 7.278 0 4.015 3.187 7.273 7.117 7.273s7.113-3.258 7.113-7.273ZM122.234 20.352c0-4.02-3.187-7.278-7.117-7.278-3.926 0-7.113 3.258-7.113 7.278 0 4.015 3.187 7.273 7.113 7.273 3.93 0 7.117-3.258 7.117-7.273ZM143.145 26.102a.845.845 0 0 1-.602-.254l-4.8-4.91a.84.84 0 0 1 0-1.176l4.8-4.907a.84.84 0 0 1 1.187-.015c.333.324.34.86.016 1.191l-4.226 4.32 4.226 4.32a.841.841 0 0 1-.601 1.43ZM151.21 26.102a.844.844 0 0 1-.776-.516.843.843 0 0 1 .175-.914l4.223-4.32-4.223-4.32a.84.84 0 1 1 1.203-1.177l4.797 4.907a.84.84 0 0 1 0 1.175l-4.797 4.91a.852.852 0 0 1-.601.255ZM337.559 13.918h-8.926c-1.016 0-1.84.824-1.84 1.844v8.937a1.84 1.84 0 0 0 1.84 1.84h8.926a1.845 1.845 0 0 0 1.851-1.84v-8.937a1.851 1.851 0 0 0-1.851-1.844ZM315.688 13.918h-8.926c-1.02 0-1.844.824-1.844 1.844v8.937c0 1.016.824 1.84 1.844 1.84h8.925a1.845 1.845 0 0 0 1.852-1.84v-8.937a1.851 1.851 0 0 0-1.851-1.844ZM358.172 14.34h-8.926c-1.02 0-1.844.824-1.844 1.84v8.937c0 1.02.825 1.844 1.844 1.844h8.926c1.02 0 1.848-.824 1.851-1.844V16.18a1.845 1.845 0 0 0-1.851-1.84ZM260.648 17.738h-71.347a2.245 2.245 0 0 0-1.957 1.121 2.236 2.236 0 0 0 0 2.254 2.241 2.241 0 0 0 1.957 1.117h71.347a2.249 2.249 0 0 0 2.235-2.246c0-1.234-1-2.238-2.235-2.246Zm0 0"
    />
  </Svg>
)

export default SvgComponent
