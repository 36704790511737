import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import {
  doc,
  getFirestore,
  runTransaction,
  getDoc,
  FieldValue,
  arrayUnion,
  updateDoc,
  setDoc,
} from "firebase/firestore";

/*
Utility functions to access and edit Firestore + tags data structures

  - Card
  - setCard(id, cardObj)
  - CardsView (prop: )
    - getCards(groupId, tagsArray)


Data Structure

cards
├── tags
│   └── See tags doc
├── cardId
│   └── createdAt
│   └── tags
│   └── authorId
│   └── authorName
│   └── content

Use React "Firebase Provider" component with Context to hand down setCard, getters, everything else
the CardsView needs an "adding" state that injects an empty card with 



*/

/*
setCard(groupId, id, cardObj)
If we get an ID, update that card to cardObj. Update keys somehow based on
Meredith's tag data structure.

HOW DO WE DEAL WITh REMOVED TAGS THAT WERE THE LAST TAG?

*/
export const setCard = (groupId, id) => {
  const groceriesColRef = collection(db, "groups");
  return addDoc(groceriesColRef, {
    created: serverTimestamp(),
    users: [{ name: userName }],
  });
};

/*
streamCards(groupId, tagsArray)
Given the above, firebase query that contaings tagsArray
OR if no tags array firebase query that sorts by createdAt

*/
export const streamCards = (groupId, tagsArray, snapshot, error) => {
  // TODO: Tag search

  const itemsColRef = collection(db, "groups", groupId, "cards");
  const itemsQuery = query(itemsColRef, orderBy("created"));
  return onSnapshot(itemsQuery, snapshot, error);
};

/*



tagsDocObject = {
    cardDict<cardID : array<tag strings>>
    tagDict<tag string : array<cardId strings>>
}


Tag search filtering:
  Data structures needed in database:
  1. (cardDict) Cards to tags: a dictionary where each card is a key and 
    its value is a list of its tags
  2. (tagDict) Tags to cards: a dictionary wheere each tag is a key and 
    its value is a list of its cards 
  
  Data structures needed in component:
  1. (curResults) some global state-tracked list of the current "set" of 
    cards in the user's search results 
  2. (curTags) some global state-tracked list of the current "set" of tags
     in the cards of the current search results 

  How filtering for search will work:
    Single tag search:
      When a user searches by the first tag, the component (something?) will
      look up the tag in tagDict and get the value pair, the list of the cards 
      that are associated with that tag. The card view will render those cards 
      by looking up those cardIDs. These cards also get deposited into curResults.

    Multi-tag search:
      Firstly, to make it so that the system is unable to suggest useless 
      second tags (tags that do not appear in any  of the cards in the current 
      search results of the user) - curTags will be generated. 
      [curTag generation] When a user wants to add a tag beyond a first tag, 
      first, the function goes into the list curResults. It looks up item 
      by item everything in curResults in cardDict to append the tags 
      associated with each card into curTags. We will, by the way, 
      remove duplicates during list generation. curTags is then the list of 
      tags that gets suggested to the user when they want to add an 
      additional tag to filter by. 
      
      (Upon successive tag filtering beyond this, assuming the invariant that
      curResults is always updated to the current search results, 
      curTags will be updated according to curResults.) 

  Secondly for the actual paring down of search results. 
  Once the user selects a second tag to sort by (let’s call this tag2), 
  we go through the list curResults once again and look up each card in 
  cardDict. If a card has `tag2`, we will keep it in curResults, 
  but if it does not, then we will remove it from curResults. 
  Once this linear search is done, the function will return, 
  and cardView will refresh. (Successive tag filtering beyond a second 
  tag will work exactly the same way.) 

*/

/*
updateTags(tagsDocObject, cardId, tagsArray) -> newTagsDocObject

Given the (potentially empty) tags object, a card's ID, and a 
(potentially empty) array of its tags (strings)
update the tags object to reflect additions and deletions of tags from cardId.

*/
export const updateTags = ({ tagsDocObject, cardId, tagsArray }) => {
  let newTagsDocObject = tagsDocObject
    ? tagsDocObject
    : {
        tagDict: {}, // tag -> cardIds
        cardDict: {}, // cardId -> tags
      };
  //let newTags = tagsArray;
  //let oldTags = cardDict[cardId];

  if (!cardId) return newTagsDocObject;

  const tagsAddedToCard = tagsDocObject?.cardDict[cardId]
    ? tagsArray.filter((x) => !tagsDocObject?.cardDict[cardId].includes(x))
    : tagsArray;
  const tagsRemovedFromCard = tagsDocObject?.cardDict[cardId]
    ? tagsDocObject?.cardDict[cardId].filter((x) => !tagsArray.includes(x))
    : [];

  // Set cardDict cardId's tags
  newTagsDocObject.cardDict[cardId] = tagsArray;

  // Now we need to update the tagDict
  // for each addedTag append cardId to
  // newTagsDocObject.tagDict[addedTag]

  tagsAddedToCard.map((addedTag) => {
    newTagsDocObject.tagDict[addedTag] = newTagsDocObject.tagDict[addedTag]
      ? newTagsDocObject.tagDict[addedTag].concat(cardId)
      : [cardId];
  });

  // for each removedTag remove cardId from
  // newTagsDocObject.tagDict[addedTag]
  //
  tagsRemovedFromCard.map((removedTag) => {
    newTagsDocObject.tagDict[removedTag] = newTagsDocObject.tagDict[removedTag]
      ? newTagsDocObject.tagDict[removedTag].filter((value) => value != cardId)
      : [];
  });

  // Check for empty cards and tags
  /*newTagsDocObject.tagDict.filter((value) => !!value);
  newTagsDocObject.cardDict.filter((value) => !!value);*/

  newTagsDocObject.tagDict = Object.fromEntries(
    Object.entries(newTagsDocObject.tagDict).filter(
      ([k, v]) => !!v && v.length > 0
    )
  );
  newTagsDocObject.cardDict = Object.fromEntries(
    Object.entries(newTagsDocObject.cardDict).filter(
      ([k, v]) => !!v && v.length > 0
    )
  );

  return newTagsDocObject;
};

/*
searchTags(tagsDocObject, tagsArray) -> suggTagsArray

Given the tags object, and a (potentially empty) array of currently searched tags
return an array of tags for which there exists at least one card that contains both
the tags in tagsArray and newTagsArray

*/

export const searchTags = (tagsDocObject, tagsArray) => {
  if (!tagsDocObject) {
    return [];
  }
  // just renaming things for local access
  const tagDict = tagsDocObject.tagDict;
  const cardDict = tagsDocObject.cardDict;

  //for each thing in tagsArray:
  //look up all cards associated with first tag and store it in [curCards]
  // if the card itself also has every other tag in the list.
  if (tagsArray.length == 0) {
    return Object.keys(tagDict);
  }

  let curCards = tagDict[tagsArray[0]];
  function checkTags(card) {
    let check;
    tagsArray.forEach((x) => {
      check = cardDict[card].includes(x);
    });
    return check;
  }
  curCards = curCards.filter(checkTags);

  // then with curCards just look up every card in that list in cardDict and
  // generate an array of possible other tags based on the tags in that dictionary
  // like, just add a tag to the list if it doesn't exist in the list already
  let suggTagsArray = [];
  curCards.forEach((x) => {
    cardDict[x].forEach((y) => {
      if (suggTagsArray.includes(y)) {
        return;
      } else {
        suggTagsArray.push(y);
      }
    });
  });

  //remove things that were in tagsArray from suggTagsArray
  tagsArray.forEach((x) => {
    for (var i = suggTagsArray.length - 1; i >= 0; i--) {
      if (suggTagsArray[i] === x) {
        suggTagsArray.splice(i, 1);
      }
    }
  });

  // and return suggTagsArray
  return suggTagsArray;
};

/*
searchCards(tagsDocObject, tagsArray) -> suggCardsArray

Given the tags object, and a (potentially empty) array of currently searched tags
return an array of cardIds which contain all of the tags in tagsArray.

*/
export const searchCards = (tagsDocObject, tagsArray) => {
  if (!tagsDocObject) {
    return [];
  }

  // just renaming things for local access
  const tagDict = tagsDocObject.tagDict;
  const cardDict = tagsDocObject.cardDict;

  if (tagsArray.length == 0) {
    return Object.keys(cardDict);
  }

  //for each thing in tagsArray:
  //look up all cards associated with first tag and store it in [curCards]
  // if the card itself also has every other tag in the list.
  let curCards = tagDict[tagsArray[0]];
  function checkTags(card) {
    let check = true;
    tagsArray.forEach((x) => {
      check = check && cardDict[card].includes(x);
    });
    return check;
  }
  const suggCardsArray = curCards.filter(checkTags);

  // and return the cardIDs that were filtered from the tags
  return suggCardsArray;
};

export const addPointsContributionToUser = async (uid, {value, ...dataObj}) => {
  if (!value) {
    console.error("Points Contribution object did not include a field \"value\", so nothing was uploaded.");
    return
  }

  const userDoc = doc(getFirestore(), "users", uid);

  setDoc(userDoc, {
    points: arrayUnion({value, ...dataObj})
  }, {merge: true});
}

