import React from "react";
import { View, Text, Image } from "react-native";
import { Card } from "./Card";
import { CardList } from "./CardList";
import { styles } from "./styles";

export const OnboardingCards = React.memo(({groupName}) => {  
  const OnboardingDict = {
    qwertyuiop: {
      tags: ["Go", "in", "depth"],
      content:
        'This card has been embedded. Click anywhere within the border to expand it. \n \n \n \n \n \n Hello there! To collapse this again, press the header, where the list of tags is.',
      authorName: "John Doe",
      authorId: "8675309"
    },
    welcome: {
      tags: ["Welcome to ReLearn Notes", "overview", "tutorial"],
      content: "Welcome to the future home of everything you could possibly want to know about " + groupName + "! \n\n  Turn your notes into ReLearn Cards and get beautiful shared study guides that are searchable and organized by design. \n\n  **Create a new card by clicking the plus button above.**",
      authorName: "John Doe",
      authorId: "2034092i3409"
    },
    tags: {
      tags: ["Tags Organize for You", "organization", "tutorial"],
      content: "Add some tags that you'd use to search for the card later. If the card is about George Washington, you might use the tags revolutionary war, president, and general. \n \n Or if the topic was dicussed in a specific lecture, or is going to be on the next test, you could tag it accordingly.",
      authorName: "Jane Doe",
      authorId: "0qw9jelkajsdf"
    },
    description: {
      tags: ["Bigger than Flashcards", "embedding", "style", "tutorial"],
      content: "Write a description of the concept. Cards can be multiple paragraphs long. \n       You can also embed other cards by typing '@' to include more information. :qwertyuiop:",
      authorName: "Jessie Doe",
      authorId: "iajsfd09w390asdfj"
    },
    markdown: {
      tags: ["Rich Editing With Markdown", "style", "tutorial"],
      content: "*Check* **this** ***out!*** ReLearn Cards support Markdown, including image embedding. Never used Markdown? Check out [this Markdown cheat sheet.](https://www.markdownguide.org/cheat-sheet/) \n Some basics include surrounding text with asterisks (*) for \n\\*Italics\\*, \\*\\*Bold\\*\\*, \\*\\*\\*or Both\\*\\*\\*\n*Italics*, **Bold**, or ***Both*** ",
      authorName: "Jane Doe",
      authorId: "a0sd09309j90d8"
    },
    embed: {
      tags: ["Nerd Out", "STEM4Lyfe", "tutorial"],
      content: "Cards support code (surround code with \`)  \n \n `if (stressed == true) useReLearn();` \n \n and equation embedding (surround with double $) \n $$4sen(α)cos^2(α/2)$$",
      authorName: "John Doe",
      authorId: "a0sd09309j90d8"
    },
  }

  const onboardingOrder = ["welcome", "tags", "description", "markdown", "embed"];

  console.log("beep boop bop");

  return (
    <View style={{ flex: 1, justifyContent: "center" }}>
      <Text style={{ textAlign: "center", fontSize: 30 }}> First time? </Text>
      <CardList cardDict={OnboardingDict} cardOrder={onboardingOrder} onSubmitEditing={() => {}} onSelect={() => {}}/>
      
    </View>
  );
});
