import React, { useState } from "react";
import { Text, StyleSheet, Button } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { ResponseType } from "expo-auth-session";
import * as Google from "expo-auth-session/providers/google";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { View, TextInput, Logo, FormErrorMessage } from "../components";
import { Images, Colors, auth } from "../config";
import { useTogglePasswordVisibility } from "../hooks";
import { GradientButton } from "../components/GradientButton";

/*
Possibly completes an authentication session on web in a window popup. The 
method should be invoked on the page that the window redirects to.
*/
WebBrowser.maybeCompleteAuthSession();

export const LoginScreen = ({ navigation }) => {
  const [errorState, setErrorState] = useState("");
  const { passwordVisibility, handlePasswordVisibility, rightIcon } =
    useTogglePasswordVisibility();

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    clientId:
      "647704183432-d2eh86ap14dnvh33psgvrfnn8kn6dtie.apps.googleusercontent.com",
  });

  React.useEffect(() => {
    if (response?.type === "success") {
      const { id_token } = response.params;
      //console.log("PROVIDER: ", provider);
      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(auth, credential);
    }
  }, [response]);

  const handleLogin = (values) => {
    const { email, password } = values;
    signInWithEmailAndPassword(auth, email, password).catch((error) =>
      setErrorState(error.message)
    );
  };
  return (
          <View style={styles.logoContainer}>
            <Logo uri={"https://relearn.fyi/logo.png"} />
            <Text style={styles.screenTitle}>ReLearn</Text>
         
            <View style={styles.borderlessButtonContainer}>
            <GradientButton
              title="Login with NetID"
              onPress={() => {
                promptAsync();
              }}
            />
          </View>
         
          </View>
          
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    flex: 1,
    marginTop: -200,
    alignItems: "center",
    paddingHorizontal: 12,
    justifyContent: "center",
  },
  screenTitle: {
    fontSize: 32,
    fontWeight: "700",
    color: Colors.black,
    paddingTop: 20,
  },
  footer: {
    marginTop: 100,
    backgroundColor: Colors.white,
    paddingHorizontal: 12,
    paddingVertical: 25,
    alignItems: "center",
  },
  footerText: {
    fontSize: 14,
    fontWeight: "700",
    color: Colors.orange,
  },
  button: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
    backgroundColor: Colors.orange,
    padding: 10,
    borderRadius: 8,
  },
  buttonText: {
    fontSize: 20,
    color: Colors.white,
    fontWeight: "700",
  },
  borderlessButtonContainer: {
    marginTop: 16,
    alignSelf: "center",
    width: 200,
  },
});
