import React from "react";
import { View, StyleSheet, Button, Image, Text, useWindowDimensions } from "react-native";
import { signOut } from "firebase/auth";
import Onboarding from "../components/Onboarding";
import { BigScreenOnboarding, SmallScreenOnboarding } from "../components/NewOnboarding";

import { auth } from "../config";

export const HomeScreen = () => {
  const { height, width } = useWindowDimensions();
  const isLargeScreen = width >= 1000;

  const handleLogout = () => {
    signOut(auth).catch((error) => console.log("Error logging out: ", error));
  };
  return (
    <View style={styles.container}>
      
      {/* <Text>Build: 4/13/2022 12:58PM</Text> */}
      {isLargeScreen ? <BigScreenOnboarding/> : <SmallScreenOnboarding/>}

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
});
