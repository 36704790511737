import React, { useCallback, useState } from "react";
import { View, TouchableOpacity, Text, TextInput, StyleSheet } from "react-native";
import PropTypes from "prop-types";
import { AutocompleteTags } from "react-native-autocomplete-tags";
import { Feather } from "@expo/vector-icons";

/*
 * A search bar is a textbox that takes in user-generated words
 * and maps the data character-by-character to all tags in the database.
 * A list of compatible possible tags will show up as the user types in data.
 * When the user selects or removes a tag, onSearch is called with the list of
 * currently selected tags.
 */

export const CardSearchBar = ({ tags, onSearch, loading, ...props }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const tagsChange = (items) => {
    setSelectedItems(items);
    onSearch(items);
  }

  return (
      <AutocompleteTags
        tags={selectedItems}
        parseChars={[',', ';', '\n']}
        suggestions={tags}
        onChangeTags={tagsChange}
        onSuggestionPress={(pressedSuggestion) => {
          const newTags = [...selectedItems, pressedSuggestion];
          setSelectedItems(newTags);
          onSearch(newTags);
        }}
        
        onTagPress={(pressedTag) => {
          const newTags = selectedItems.filter((value) => value != pressedTag);
          setSelectedItems(newTags);
          onSearch(newTags);
        }}
        labelExtractor={(tag) => tag}
        inputStyle={{ outline: 'none'}}
        inputProps={{ placeholder: selectedItems.length == 0 ? "Search a tag here..." : "Enter more tags here..."}}
        containerStyle={styles.inputBox}
        tagContainerStyle={{padding: 5}}
        flatListProps={{listKey: (Math.random() * 10000000)}}
        flatListStyle={styles.list}
        allowCustomTags={false}
        renderSuggestion={(suggestion, onPress) => {
          return (
            <TouchableOpacity style={styles.suggestion} onPress={onPress}>
              <Text>#{suggestion}</Text>
            </TouchableOpacity>
          )
        }}
      />
    
  );
};

CardSearchBar.propTypes = {
  /**
   * tags are all the tags that have been used by at least one card in this ReLearn group
   */
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  /**
   * onSearch is the function to run after a user adds another tag to their search
   */
  onSearch: PropTypes.func,
};

CardSearchBar.defaultProps = {
  tags: [],
  onSearch: () => {},
  loading: false,
};

const styles = StyleSheet.create({
  inputBox: {
    backgroundColor: "white",
    elevation: 10,
    //overflow: "hidden",
    padding: 10,
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: {
      height: 3,
      width: 0
    }
  },
  list: {
    //overflow: "hidden",
    elevation: 9,
    marginTop: 20,
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: {
      height: 3,
      width: 0
    },
    backgroundColor: "white"
  },
  suggestion: {
    borderBottomWidth: 1,
    borderBottomColor: "lightgray",
    paddingTop: 10,
    backgroundColor: "white",
    margin: 5
  }
});
