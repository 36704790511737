// types/index.js
import { shape, number, string, oneOf, arrayOf } from 'prop-types';

/*
PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
      content: PropTypes.string.isRequired,
      authorName: PropTypes.string.isRequired,
      authorId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
  })

*/

export const card = shape({
  id: string,
  tags: arrayOf(string),
  content: string,
  authorName: string.isRequired,
  authorId: string.isRequired,
  likedList: arrayOf(string),
})

export const userType = shape({
  id: number,
  firstName: string.isRequired,
  lastName: string.isRequired,
  company: string,
  role: oneOf(['user', 'author']),
  address: shape({
    id: number.isRequired,
    street: string.isRequired,
    street2: string,
    city: string.isRequired,
    state: string.isRequired,
    postal: number.isRequired
  })
});