import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  View,
  ActivityIndicator,
  StyleSheet,
  Button,
  Text,
  useWindowDimensions,
} from "react-native";
import { signOut, getAuth, currentUser } from "firebase/auth";

import { auth } from "../config";
import { doc, getDoc, getFirestore} from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { CardsView } from "../components/cards/CardsView";
import { JoinGroupDialog } from "../components/groups/JoinGroupDialog";
import { useNavigation } from "@react-navigation/native";

export const GroupLoading = (props) => {

    const docRef = doc(getFirestore(), "groups", props.route.params.groupId);
    const [dataValue, loading, error, snapshot] = useDocumentDataOnce(docRef);
    const navigation = useNavigation();
  
    if (!loading && !error) {
      console.log(dataValue);
      console.log(snapshot);

      if (!dataValue.name) navigation.navigate("Home", {})

      navigation.navigate(
        "GroupScreen",
        {
          name: dataValue.name,
          groupId: snapshot.id,
          color: dataValue.color,
          members: dataValue.members,
          leftGroup: false
        }
      )
    }
    
    return (
      <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
        <ActivityIndicator size="large" />
      </View>
    )
  }