/** This component wraps a Group. It takes in a groupID and fetches that group from Firestore.
 *  It takes in other props that are needed for the group component, like callbacks, and passes those on.
 */

import React from "react";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth, currentUser } from "firebase/auth";

import { GroupItem } from "./GroupItem";

export const groupConverter = {
    toFirestore: function(group) {
      return { tags: group.tags, content: group.content, authorName: group.authorName, authorId: group.authorId };
    },
    fromFirestore: function(snapshot, options) {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        ref: snapshot.ref,
        courseCode: data.courseCode,
        dateCreated: data.dateCreated,
        semester: data.semester,
        name: data.name,
        color: data.color,
        members: data.members,
        loungePopulation: data.loungePopulation,
        loungeCapacity: data.loungeCapacity,
      };
    },
};

export const GroupDataWrapper = ({
  groupId,
  style,
  onPress,
  ...props
}) => {
    
    //console.log(groupId);
    const ref = doc(getFirestore(), "groups", groupId).withConverter(groupConverter);
    
    const [groupValue, groupIsLoading, groupError, groupSnapshot] = useDocumentData(ref, {
        snapshotListenOptions: { includeMetadataChanges: true },
      });
    
    if (groupError) {
        console.log("Error: ", groupError);
    }

    const loadingGroup = {
      id: groupId,
      courseCode: "LOADING",
      name: "Loading Group",
      color: "gray",
      members: ["Loading"],
      loungePopulation: 0,
      loungeCapacity: 0,
    };

    const user = getAuth().currentUser

    const blankGroup = {
      id: groupId,
      courseCode: "Error",
      name: "Group Doesn't Exist",
      color: "red",
      members: ["Loading"],
      loungePopulation: 0,
      loungeCapacity: 0,
    };

    return (
        <GroupItem groupItem={groupIsLoading ? loadingGroup : (groupValue ? groupValue : blankGroup)} style={style} onPress={onPress} {...props}/>
    )
};
