import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={"100%"}
    height={"100%"}
    viewBox="0 0 400 349"
    {...props}
  >
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M66.313 121.477c-.934.097-1.876.117-2.817.066a.618.618 0 0 0-.676.535l-1.793 13.48c-.14.981-.539 1.903-1.16 2.672a5.813 5.813 0 0 0-1.308 4.45v22.902s-.118 3.055 2.226 3.055c2.809.351 5.645.351 8.453 0 0 0 1.875-.356 1.875-3.176v-22.55a5.507 5.507 0 0 0-1.16-4.481 5.629 5.629 0 0 1-1.121-2.657l-1.867-13.765a.614.614 0 0 0-.653-.531Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="M65.004 169.285c-1.418 0-2.836-.086-4.242-.262a2.29 2.29 0 0 1-1.692-.695c-.953-.992-.902-2.687-.898-2.762v-22.863a6.22 6.22 0 0 1 1.398-4.719 5.1 5.1 0 0 0 1.075-2.48l1.792-13.477a1.01 1.01 0 0 1 1.102-.867c.914.047 1.828.028 2.734-.066h.012a.998.998 0 0 1 1.063.863l1.867 13.762a5.231 5.231 0 0 0 1.047 2.472 5.895 5.895 0 0 1 1.238 4.754v22.516c0 3.101-2.168 3.55-2.191 3.555-1.43.18-2.868.27-4.305.27Zm-1.559-47.355a.23.23 0 0 0-.242.195l-1.793 13.484a5.871 5.871 0 0 1-1.242 2.864 5.46 5.46 0 0 0-1.227 4.16l.004.047v22.902c0 .027-.043 1.461.684 2.211.305.312.723.477 1.156.457h.05c2.774.352 5.58.352 8.352 0 .04-.008 1.54-.352 1.54-2.79l.007-22.616a5.137 5.137 0 0 0-1.078-4.172 6.019 6.019 0 0 1-1.207-2.844l-1.867-13.77a.23.23 0 0 0-.238-.195c-.953.098-1.91.117-2.867.067h-.032Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="M63.477 143.09h2.847a4.27 4.27 0 0 1 4.27 4.27v10.718a4.274 4.274 0 0 1-4.27 4.274h-2.847a4.27 4.27 0 0 1-4.266-4.274V147.36c0-2.359 1.91-4.27 4.266-4.27Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M11.148 339.953h9.282l4.414-35.824H11.148Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M8.781 336.922H27.06c6.433 0 11.644 5.219 11.644 11.656v.379H8.785ZM5.316 212s-4.023 4.55 2.637 22.29l-1.172 97.25 21.582-.423 4.742-47.437 8.637-69.227Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="m49.145 225.445-.239-.035C21.301 221.195.465 217.156.258 217.117l-.258-.05 7.238-17.618c-.058-1.844-1.625-53.539 1.375-68.39 2.903-14.38 16.586-16.735 18.149-16.957l1.562-5.192 19.785 10.3 3.45 11.888.699 32.57Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M61.059 158.406c-1.801 0-3.52.75-4.743 2.074h-33.25l-12.18 2.594 2 15.438 43.325-9.325a6.472 6.472 0 0 0 7.86 1.442 6.485 6.485 0 0 0 3.28-7.293 6.476 6.476 0 0 0-6.292-4.93ZM54.973 81.309c7.765 4.246 10.62 13.992 6.375 21.765-4.246 7.774-13.985 10.63-21.75 6.38-7.766-4.247-10.618-13.993-6.375-21.766 4.246-7.774 13.984-10.63 21.75-6.38Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="m32.215 82.559-.778-1.567 3.91-1.941S39.66 72.027 47.462 74c7.805 1.973 11.316 3.156 11.316 3.156l3.899 1.961-1.957 1.95 3.511 1.183-2.347 1.164 2.73 1.57-1.468 8.047s-2.442-6.101-7.133-3.77c-4.692 2.333-13.27-1.206-13.27-1.206l-7.457 14.441s-1.535-5.062-4.277-3.715c0 0-7.004-11.164 1.207-16.222Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="m13.098 181.406-3.801-11.32-.922-37.738 1.621-5.352a14.95 14.95 0 0 1 11.281-10.316 14.947 14.947 0 0 1 14.442 5 14.97 14.97 0 0 1 2.504 15.09l-8.684 22.273.441.855 18.817-.152 6.68 12.04Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M201.336 176.754a5.781 5.781 0 0 0 2.523-4.203 5.792 5.792 0 0 0-1.597-4.633l9.273-18.387-10.62 1.235-7.18 17.27a5.834 5.834 0 0 0-.07 7.737 5.82 5.82 0 0 0 7.67.98ZM275.71 306.984h-8.358l-3.977-32.261h12.336Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M277.844 315.094h-26.95v-.34c0-5.8 4.696-10.5 10.489-10.5h16.46Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M230.035 306.984h-8.36l-3.972-32.261h12.332Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M230.121 315.094h-26.95v-.34c0-5.8 4.7-10.5 10.493-10.5h16.457ZM229.352 170.715l-8.18 18.863-6.75 108.418 17.043-.683 4.656-51.684 14.36-46.031 8.253 51.32 3.407 45.031 14.105.61 5.617-125.844Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="m270.242 77.64-23.71.594-13.919 7.153-.765 52.765-8.68 36.434s54.242 3.734 61.742-1.723l-8.863-41.62 11.887-44.981Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="m236.273 86.262-2.207-1.492s-9.144 2.742-11.847 14.945c0 0-2.067 6.12-2.977 10.332-1.039 4.828-24.023 49.18-24.023 49.18l13.761 3.949 25.227-40.836Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M287.563 183.52a5.787 5.787 0 0 1-1.266-4.735 5.79 5.79 0 0 1 2.812-4.015l-3.847-20.23 9.87 4.116 2.141 18.582a5.822 5.822 0 0 1-9.71 6.281Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="m284.367 86.895 2.531-.829s8.036 5.16 7.27 17.637c0 0 .3 6.457.016 10.754-.332 4.93 4.09 53.902 4.09 53.902h-14.317l-6.137-44.351Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M276.063 55.5c0 9.086-7.36 16.453-16.434 16.453-9.078 0-16.438-7.367-16.438-16.453 0-9.082 7.36-16.45 16.438-16.45 9.055-.023 16.41 7.305 16.433 16.368v.082Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M243.246 33.406c1.926-4.144 5.652-3.324 8.91-1.918 4.125-.918 8.051-3.66 12.496-2.039 4.38 6.375 19.086 4.5 15.957 14.88-.004 2.487 4.676 1.038 3.86 5.112 2.48 7.84-8.95 22.684-15.512 19.559 1.625-2.977 5.332-9.734-.289-10.406-12.094 11.261-1.25-21.446-16.445-11.934-5.032 4.61-11.918-8.644-8.977-13.254Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M290.441 185.16a5.447 5.447 0 0 0 4.489-1.09 5.471 5.471 0 0 0 2.047-4.148l41.27-38.879-9.286-7.414-37.715 40.644a5.494 5.494 0 0 0-5.234 5.086 5.495 5.495 0 0 0 4.43 5.801Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#cbcbcb",
        fillOpacity: 1,
      }}
      d="m332.398 147.328-8.027-9.418a2.465 2.465 0 0 1-.558-1.96c.109-.708.519-1.329 1.12-1.708l10.454-6.55a6.8 6.8 0 0 1 9.574.8 6.81 6.81 0 0 1-.774 9.582l-8.078 9.274a2.445 2.445 0 0 1-1.859.84 2.455 2.455 0 0 1-1.852-.86Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M310.809 339.168h-10.09l-4.801-38.953h14.894Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M313.383 348.957h-32.535v-.414c0-7 5.668-12.672 12.664-12.672h19.87Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M391.469 339.168h-10.09l-4.8-38.953h14.89Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M394.043 348.957h-32.54v-.414c.005-7 5.673-12.672 12.665-12.672h19.875ZM364.328 167.977l10.168-37.61-13.195-7.426h-18.418l-7.16 7.672s-11.97 8.336-9.075 17.57l4.676 21.032-7.148 33.402-36.012 49.985-8.523 49.093 33.554 8.805 36.285-71.508 17.594 79.18 32.73-1.649-11.277-96.003s-3.214-29.247-20.066-47.508Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M385.332 201.254a5.458 5.458 0 0 0 1.598-4.34 5.456 5.456 0 0 0-2.297-4.012l-7.895-53.289-11.324 3.582 11.336 51.32a5.504 5.504 0 0 0 1.3 7.184 5.49 5.49 0 0 0 7.282-.445Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#cbcbcb",
        fillOpacity: 1,
      }}
      d="m378.691 147.977-12.289 1.418a2.449 2.449 0 0 1-2.726-2.512l.394-12.336c-.406-3.73 2.278-7.09 6.004-7.508a6.803 6.803 0 0 1 7.516 5.988l3.187 11.883a2.449 2.449 0 0 1-.343 2.012 2.438 2.438 0 0 1-1.743 1.055Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M331.496 112.246V96.402c0-11.73 9.5-21.238 21.219-21.238 11.715 0 21.215 9.508 21.215 21.238v15.844a2.857 2.857 0 0 1-2.848 2.856h-36.734a2.86 2.86 0 0 1-2.852-2.856Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#9f616a",
        fillOpacity: 1,
      }}
      d="M362.004 91.695c4.121 7.543 1.348 17.004-6.188 21.13-7.539 4.124-16.992 1.35-21.113-6.196-4.121-7.543-1.348-17.004 6.188-21.129 7.539-4.121 16.992-1.352 21.113 6.195Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M326.477 97.668c.011-9.273 7.52-16.785 16.785-16.797h3.164c9.265.012 16.773 7.524 16.785 16.797v.316h-6.691l-2.286-6.394-.453 6.394h-3.46l-1.153-3.226-.23 3.226h-22.461Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M346.824 116.883a2.8 2.8 0 0 1-.222-2.985c3.355-6.386 8.05-18.191 1.816-25.472l-.45-.524h18.106v27.207l-16.445 2.903a2.83 2.83 0 0 1-2.805-1.129Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M180.41 131.367c-.23.238-.441.492-.633.762l-29.5-.723-3.441-6.293-10.168 3.957 5.012 11.735a4.757 4.757 0 0 0 4.945 2.855l33.332-4.027a6.243 6.243 0 0 0 11.137-3.535 6.25 6.25 0 0 0-3.711-6.059 6.238 6.238 0 0 0-6.973 1.328ZM84.242 310.68h9.7l4.613-37.446H84.242Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M81.77 307.508h19.101c6.723 0 12.172 5.457 12.176 12.183v.399H81.77Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="m172.32 310.465 8.922-3.817-10.457-36.25-13.164 5.63Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="m168.8 308.52 17.567-7.508v-.004c6.184-2.64 13.336.23 15.98 6.422l.153.363-28.762 12.297Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="m131.39 139.48.7 11.375-20.5 2.196-21.453 2.3 6.312-17.273Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#e4e4e4",
        fillOpacity: 1,
      }}
      d="M132.11 143.563c-.231-.09-23.391-9.106-37.614-1.735l-.351.18-2.836-13.98-1.618-30.055 10.52-4.977 2.898-6.492 19.735-1.168 4.054 5.562 13.805 4.532-8.25 48.265Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#e4e4e4",
        fillOpacity: 1,
      }}
      d="m132.836 116.434 2.441-19.196 5.559-1.691.09.035c.129.047 3.152 1.203 3.976 5.387.793 4.02 6.77 29.015 6.832 29.27l.059.25-14.043 5.62Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="m165.098 303.883 18.691-3.84-29.875-82.508-10.226-28.144s-6.485-22.567-9.415-31.297l-1.77-11.719-41.858 4.64s-11.692 17.184-8.141 45.192l-1.606 105.277 18.692.957 15.66-106.175 27.48 58.207s8.66 24.836 14.856 30.222Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M107.43 154.922a6.4 6.4 0 0 0-.985.055l-19.527-22.145 2.273-6.805-9.816-4.773-5.191 11.656a4.766 4.766 0 0 0 1.273 5.574l25.613 21.723a6.245 6.245 0 0 0 10.16 5.773 6.25 6.25 0 0 0-3.8-11.059Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#e4e4e4",
        fillOpacity: 1,
      }}
      d="m77.61 124.535 7.632-21.765c.86-2.563 1.934-4.122 3.195-4.633a1.966 1.966 0 0 1 1.586-.012l3.422 4.328 3.176 13.36-8.144 19.175Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M128.14 80.832H94.626a2.612 2.612 0 0 1-2.602-2.605V63.77c0-10.7 8.668-19.375 19.36-19.375 10.691 0 19.36 8.675 19.36 19.375v14.457a2.612 2.612 0 0 1-2.602 2.605Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#ffb7b7",
        fillOpacity: 1,
      }}
      d="M129.555 66.29c0 7.843-6.356 14.206-14.196 14.206-7.836 0-14.191-6.363-14.191-14.207s6.355-14.203 14.191-14.203c7.84 0 14.196 6.36 14.196 14.203Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M135.32 65.215h-20.492l-.21-2.945-1.052 2.945h-3.156l-.418-5.836-2.082 5.836h-6.105v-.29c.011-8.46 6.86-15.316 15.312-15.323h2.89c8.454.007 15.305 6.863 15.313 15.324Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#2f2e41",
        fillOpacity: 1,
      }}
      d="M114.66 83.531c-.156 0-.308-.015-.46-.043L99.19 80.84V56.016h16.52l-.41.48c-5.688 6.64-1.403 17.41 1.66 23.238.46.871.383 1.93-.203 2.723a2.6 2.6 0 0 1-2.098 1.074Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M294.418 143.195c-.938.094-1.879.114-2.816.067a.612.612 0 0 0-.676.531l-1.797 13.484a5.485 5.485 0 0 1-1.156 2.668 5.831 5.831 0 0 0-1.313 4.45v22.902s-.113 3.055 2.23 3.055c2.805.351 5.645.351 8.45 0 0 0 1.879-.356 1.879-3.176v-22.547a5.526 5.526 0 0 0-1.16-4.484 5.635 5.635 0 0 1-1.125-2.657l-1.864-13.765a.616.616 0 0 0-.652-.528Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="M293.105 191a34.26 34.26 0 0 1-4.242-.262 2.273 2.273 0 0 1-1.687-.695c-.957-.988-.903-2.688-.899-2.762v-22.863a6.22 6.22 0 0 1 1.399-4.719 5.097 5.097 0 0 0 1.07-2.476l1.797-13.48c.074-.54.559-.923 1.102-.868.91.047 1.824.027 2.734-.066h.012c.523-.04.988.34 1.062.863l1.863 13.766c.13.898.489 1.75 1.047 2.468a5.884 5.884 0 0 1 1.239 4.754v22.516c0 3.105-2.168 3.55-2.192 3.554-1.426.18-2.863.27-4.305.27Zm-1.558-47.355a.227.227 0 0 0-.238.199l-1.797 13.484a5.838 5.838 0 0 1-1.242 2.86 5.462 5.462 0 0 0-1.223 4.16v22.949c0 .027-.04 1.46.683 2.215.305.308.727.472 1.16.453l.048.004a33.6 33.6 0 0 0 8.355 0c.035-.012 1.539-.352 1.539-2.793l.004-22.617a5.11 5.11 0 0 0-1.078-4.172 6.025 6.025 0 0 1-1.207-2.844l-1.863-13.766a.236.236 0 0 0-.239-.199c-.953.098-1.914.117-2.867.067h-.035Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="M291.582 164.805h2.848a4.27 4.27 0 0 1 4.265 4.273v10.715a4.27 4.27 0 0 1-4.265 4.273h-2.848a4.274 4.274 0 0 1-4.27-4.273v-10.715a4.274 4.274 0 0 1 4.27-4.273Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M197.828 124.625c-.937.094-1.879.117-2.816.066a.612.612 0 0 0-.676.532l-1.797 13.484a5.47 5.47 0 0 1-1.156 2.668 5.836 5.836 0 0 0-1.313 4.45v22.902s-.113 3.054 2.23 3.054c2.805.352 5.645.352 8.45 0 0 0 1.879-.355 1.879-3.172v-22.55a5.526 5.526 0 0 0-1.16-4.485 5.674 5.674 0 0 1-1.125-2.656l-1.864-13.766a.614.614 0 0 0-.652-.527Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="M196.516 172.434c-1.418 0-2.832-.09-4.239-.266a2.272 2.272 0 0 1-1.691-.691c-.957-.993-.902-2.692-.899-2.762v-22.867a6.22 6.22 0 0 1 1.399-4.72 5.06 5.06 0 0 0 1.07-2.476l1.797-13.48c.074-.54.559-.922 1.102-.867.91.05 1.824.027 2.734-.067h.012c.523-.039.988.34 1.062.864l1.864 13.765c.128.899.492 1.75 1.046 2.469a5.905 5.905 0 0 1 1.243 4.758v22.515c0 3.102-2.172 3.547-2.192 3.551-1.43.18-2.867.274-4.308.274Zm-1.555-47.36a.237.237 0 0 0-.242.2l-1.797 13.484a5.881 5.881 0 0 1-1.238 2.863 5.451 5.451 0 0 0-1.227 4.156v22.95c0 .03-.039 1.46.688 2.214.3.309.722.473 1.156.454l.047.003a33.6 33.6 0 0 0 8.355 0c.035-.007 1.54-.351 1.54-2.789l.003-22.62a5.113 5.113 0 0 0-1.074-4.169 5.978 5.978 0 0 1-1.207-2.847l-1.867-13.766a.227.227 0 0 0-.239-.195 18.99 18.99 0 0 1-2.867.066c-.012-.004-.023-.004-.031-.004Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="M194.992 146.234h2.848a4.27 4.27 0 0 1 4.265 4.274v10.719c0 2.359-1.91 4.27-4.265 4.27h-2.848a4.267 4.267 0 0 1-4.265-4.27v-10.72a4.27 4.27 0 0 1 4.265-4.273Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M181.457 104.96c-.937.099-1.879.118-2.816.067a.618.618 0 0 0-.676.535l-1.797 13.481a5.483 5.483 0 0 1-1.156 2.672 5.813 5.813 0 0 0-1.309 4.45v22.901s-.117 3.055 2.227 3.055c2.804.352 5.644.352 8.449 0 0 0 1.879-.355 1.879-3.176v-22.55a5.533 5.533 0 0 0-1.16-4.485 5.66 5.66 0 0 1-1.125-2.652l-1.864-13.766a.617.617 0 0 0-.652-.531Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#3f3d56",
        fillOpacity: 1,
      }}
      d="M180.145 152.77a34.15 34.15 0 0 1-4.239-.262 2.29 2.29 0 0 1-1.691-.696c-.957-.992-.903-2.69-.899-2.761v-22.863a6.22 6.22 0 0 1 1.399-4.72 5.058 5.058 0 0 0 1.07-2.48l1.797-13.476c.074-.54.559-.926 1.102-.867.91.046 1.824.027 2.734-.067h.012a1 1 0 0 1 1.062.863l1.863 13.762a5.27 5.27 0 0 0 1.047 2.473 5.897 5.897 0 0 1 1.243 4.754v22.515c0 3.102-2.172 3.551-2.192 3.555-1.43.18-2.867.27-4.308.27Zm-1.555-47.356a.23.23 0 0 0-.242.195l-1.797 13.485a5.865 5.865 0 0 1-1.238 2.863 5.46 5.46 0 0 0-1.227 4.16v22.95c0 .027-.04 1.46.687 2.21.301.313.723.477 1.157.457h.047c2.773.352 5.582.352 8.355 0 .035-.007 1.54-.351 1.54-2.789l.003-22.617a5.118 5.118 0 0 0-1.074-4.172 5.98 5.98 0 0 1-1.207-2.844l-1.867-13.769a.23.23 0 0 0-.239-.195 18.99 18.99 0 0 1-2.867.066h-.031Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#6c63ff",
        fillOpacity: 1,
      }}
      d="M178.621 126.574h2.848a4.267 4.267 0 0 1 4.265 4.27v10.719a4.27 4.27 0 0 1-4.265 4.273h-2.848a4.27 4.27 0 0 1-4.266-4.274v-10.718c0-2.36 1.91-4.27 4.266-4.27Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#f0f0f0",
        fillOpacity: 1,
      }}
      d="M44.305 25.125c0 7.54-6.11 13.652-13.645 13.652-7.531 0-13.64-6.113-13.64-13.652 0-7.543 6.109-13.656 13.64-13.656 7.535 0 13.645 6.113 13.645 13.656ZM358.629 13.652c0 7.543-6.11 13.657-13.64 13.657-7.536 0-13.645-6.114-13.645-13.657 0-7.539 6.11-13.652 13.644-13.652 7.532 0 13.64 6.113 13.64 13.652ZM198.738 51.887c0 5.43-4.398 9.828-9.824 9.828-5.422 0-9.82-4.399-9.82-9.828 0-5.43 4.398-9.832 9.82-9.832 5.426 0 9.824 4.402 9.824 9.832Zm0 0"
    />
  </Svg>
)

export default SvgComponent
