import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  View,
  TouchableOpacity,
  Pressable,
  Text,
  TextInput,
  StyleSheet,
  LayoutAnimation,
} from "react-native";
import PropTypes from "prop-types";
import { card as cardType } from "./types";
import { Feather, Ionicons } from "@expo/vector-icons";
import Collapsible from "react-native-collapsible";
import { ErrorBoundary } from "react-error-boundary";
import { updateCard } from "./cardsUtils";
import { CardsView } from "./CardsView";
import { styles as cardStyles } from "./styles";
import { LikeButton } from "../LikeButton.js";
import Markdown from "react-native-markdown-display";
import { MathJaxSvg } from "react-native-mathjax-html-to-svg";
import { OnboardingCards } from "./OnboardingCards";
import { Tag } from "./Tag";

const EditorButton = ({onPress, iconName}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.editorButton}>
        <Feather name={iconName} size={16} color="black"/>
      </View>
    </TouchableOpacity>
  )
}

const ParsedText = ({ text, isEditing, cardDict }) => {
  return text.split(/(:\S{20,}?:|\$\$.*?\$\$)/g).map((elem) => {
    if (elem.startsWith(":") && !elem.includes(" ")) {
      // `:...:` pattern means Card embed.

      let element;
      if (elem.includes("groups/") && elem.includes("/cards/")) {
        element = elem.slice(1, -1).split("/").pop();
      } else {
        element = elem.slice(1, -1);
      }

      if (!!cardDict && !!cardDict[element]) {
        return (
          <Card
            key={elem}
            card={cardDict[element]}
            cardDict={cardDict}
            isPreview={true}
            isEditor={isEditing}
            onSelect={(path) => console.log(path)}
          />
        );
      }
    }
    if (elem.startsWith("$$")) {
      // `$$...$$` pattern means LaTeX/MathJax embed.
      return (
        <MathJaxSvg key={elem} fontSize={3} fontCache={true}>
          {elem}
        </MathJaxSvg>
      );
    }

    // No ReLearn patterns means this is plain or Markdown text.
    return (
      <Markdown
        style={{
          heading1: { fontSize: 20, fontWeight: "400" },
          heading2: { fontSize: 20 },
          heading3: { fontSize: 16 },
        }}
        key={elem} /*style={styles.content}*/
      >
        {elem}
      </Markdown>
    );
  });
};

/**
 * A card is a summary of a concept or term described with
 * text, images (TODO), and links to other cards (TODO)
 * Instead of titles, cards have tags.
 * TODO: Cards can be liked, edited, and starred.
 * When isPreview is true, the card will be summarized and the entire
 * view will act as a "tap to expand" button
 */
const BasicCard = ({
  isPreview,
  onPreviewCollapse,
  isEditor,
  onSubmitEditing,
  card: { id, groupId, tags, content, authorName, authorId, likedList },
  cardDict,
  style,
  onLike,
  ...props
}) => {
  const [isEditing, setEditing] = useState(isEditor);
  const [isEmbeddingCard, setEmbeddingCard] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [title, setTitle] = useState(tags[0]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [height, setHeight] = useState(30);
  const [localContent, setLocalContent] = useState(content);
  const [localSelection, setLocalSelection] = useState({start: 0, end: 0});
  const [localTags, setLocalTags] = useState(tags);
  const [editWarning, setEditWarning] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const content_input = useRef();

  //console.log("printing some things ", id, tags, content, authorName)

  useEffect(() => {
    // The only time local tags will differ from the server
    // is new, unpublished cards (i.e. server has no tags for us yet)
    if (id) setLocalTags(tags);
  }, [tags]);
  useEffect(() => {
    // The only time local tags will differ from the server
    // is new, unpublished cards (i.e. server has no tags for us yet)
    if (!isEditing) setLocalContent(content);
    else {
      setEditWarning(true);
    }
  }, [content]);
  useEffect(() => {
    setEditing(isEditor);
  }, [isEditor]);

  const onEmbedPreviewTapped = (cardId) => {
    // inject cardId into current cursor position
    console.log("CARD PICKED: ", cardId);
    const newString =
      localContent.slice(0, cursorPosition - 1) +
      "\n:" +
      cardId +
      ":\n" +
      localContent.slice(cursorPosition);
    setLocalContent(newString);
    setEmbeddingCard(false);
  };

  const wrapSelection = (delim) => {
    if (localSelection.start == localSelection.end) {
      return
    }
    const preString = localContent.slice(0, localSelection.start);
    const midString = localContent.slice(localSelection.start, localSelection.end).trim();
    const endString = localContent.slice(localSelection.end);

    let newString;
    let newStart;
    let newEnd;

    if (localContent.indexOf(delim + midString + delim) >= 0) {
      newString = preString.slice(0, preString.length - delim.length) + midString + endString.slice(delim.length)
      newStart = localContent.indexOf(midString) - delim.length;
      newEnd = newStart + midString.length;
    } else {
      newString = preString + delim + midString + delim + endString;
      newStart = localContent.indexOf(midString) + delim.length;
      newEnd = newStart + midString.length;
    }

  

    setLocalContent(newString);
    setLocalSelection({start: newStart, end: newEnd});
    content_input.current.focus();
  }

  // Submit to firestore and callback
  const toggleEditing = () => {
    if (!!isEditing && onSubmitEditing) {
      onSubmitEditing({
        id: id,
        groupId: groupId,
        tags: newTag != "" ? localTags.concat(newTag) : localTags,
        content: localContent,
        authorName: authorName,
        authorId: authorId,
      });
      setEditWarning(false);
    }
    setEditing(!isEditing);
    setNewTag("");
  };

  const toggleTutorial = () => {
    setShowTutorial(!showTutorial);
  };

  const saveTitle = (tag) => {
    const newTags = [tag, ...localTags.slice(1)];

    console.log(newTags);

    if (!!id) {
      updateCard({
        id: id,
        groupId: groupId,
        tags: newTags,
        authorName: authorName,
        authorId: authorId,
      });
    } else setLocalTags(newTags);
  };

  const addTag = (tag) => {
    // concat local tag, update server if this card is already published.
    // DON'T USE ONSUBMITEDITING. Use updateCard(). OnsubmitEditing
    // will tell embed cards to close.

    // Concat the new tag.

    const newTags = localTags.concat(tag);

    if (localTags.length == 0) setTitle(tag);

    // Only update server if this card is already published.
    if (!!id) {
      updateCard({
        id: id,
        groupId: groupId,
        tags: newTags,
        authorName: authorName,
        authorId: authorId,
      });
    } else setLocalTags(newTags);

    // Reset tag text box.
    setNewTag("");
  };

  // Removes tag at index
  const removeTag = (index) => {
    var newTags = [...localTags];
    newTags.splice(index, 1);

    // Only update server if this card is already published.
    if (!!id) {
      updateCard({
        id: id,
        groupId: groupId,
        tags: newTags,
        authorName: authorName,
        authorId: authorId,
      });
    } else setLocalTags(newTags);
  };

  

  return (
    <View style={{ ...style }}>
      {/*
      Top View(Big First Tag, smaller tags after)
      Middle View (Content Text)
      Bottom View (Edit button on right and eventually likes)
      */}
      <TouchableOpacity disabled={!isPreview} onPress={onPreviewCollapse}>
          <View style={styles.tags}>
            {isPreview && <Ionicons name="chevron-up" size={20} />}
            {(!isEditing) ? (
              <Text style={{ fontSize: 22, fontWeight: "500" }}>{localTags[0]}</Text>
            ) : ( localTags.length > 0 &&
              <TextInput
                style={{ fontSize: 22, fontWeight: "500", flex: 1 }}
                value={title}
                onChangeText={setTitle}
                onSubmitEditing={({ nativeEvent: { text } }) => {
                  if (text == "") {
                    // The user must not have wanted this tag
                    // removeTag(index);
                  } else {
                    saveTitle(text);
                  }
                }}
              />
            )}

            {localTags.slice(1).map((tag, index) => {
              return (
                <Tag
                  onPress={() => {
                    LayoutAnimation.configureNext(
                      LayoutAnimation.Presets.spring
                    );
                    removeTag(index + 1);
                  }}
                  text={tag}
                  pressable={isEditing}
                  key={index + 1}
                />
              );
            })}
            {isEditing && (
              <TextInput
                style={{
                  fontSize:
                    localTags.length == 0
                      ? 20
                      : 16 /* Make the first tag bigger as a title */,
                }}
                autoFocus={
                  localTags.length ==
                  0 /* focus tag if no tags, content if tags */
                }
                placeholder={"New Tag"}
                value={newTag}
                onChangeText={(text) => setNewTag(text)}
                onSubmitEditing={({ nativeEvent: { text } }) => {
                  if (text == "") {
                    // The user must not have wanted this tag
                    // removeTag(index);
                  } else {
                    addTag(text);
                  }
                }}
              />
            )}
            {isEditing && (
              <TouchableOpacity
                onPress={() => {
                  LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
                  addTag(newTag);
                }}
              >
                <Feather name="plus-circle" size={16} color="green" />
              </TouchableOpacity>
            )}
          </View>
      </TouchableOpacity>
      {isEditing ? (
        <View style={{paddingBottom: 10}}>
          <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
            <EditorButton iconName="bold" onPress={() => wrapSelection("**")} />
            <EditorButton iconName="italic" onPress={() => wrapSelection("*")} />
            <EditorButton iconName="list" onPress={() => {
              let listString = "";
              if (localSelection.start == localSelection.end) {
                listString = "\n - \n - \n - ";
              } else {
                const selection = localContent.slice(localSelection.start, localSelection.end).split("\n");
                for (let i = 0; i < selection.length; i++) {
                  selection[i] = "\n - " + selection[i];
                }
                selection.forEach(elem => {
                  listString = listString + elem;
                })
              }
              setLocalContent(
                localContent.slice(0, localSelection.start) +
                listString +
                localContent.slice(localSelection.end)
              );
              content_input.current.focus();
            }} />
            <EditorButton iconName="code" onPress={() => wrapSelection("`")} />
            <EditorButton iconName="link" onPress={() => {
              const start = localSelection.start;
              const end = localSelection.end;
              let newString;
              if (start == end) {
                newString = localContent.slice(0, start) + "[link text](https://replace this with your link.com)" + localContent.slice(end);
              } else {
                newString = localContent.slice(0, start) + "[link text](" + localContent.slice(start, end) + ")" + localContent.slice(end);
              }
              setLocalContent(newString);
              content_input.current.focus();
            }} />
            <EditorButton iconName="image" onPress={() => {
              const start = localSelection.start;
              const end = localSelection.end;
              let newString;
              if (start == end) {
                newString = localContent.slice(0, start) + "![](link to your image must end with .jpg or .png etc, and it goes in here)" + localContent.slice(end);
              } else {
                newString = localContent.slice(0, start) + "![](" + localContent.slice(start, end) + ")" + localContent.slice(end);
              }
              setLocalContent(newString);
              content_input.current.focus();
            }} />
          </View>
        <TextInput
          autoFocus={
            !(localTags.length == 0) /* focus tag if no tags, content if tags */
          }
          ref={content_input}
          selection={localSelection}
          multiline={true}
          blurOnSubmit={false}
          returnKeyType="none"
          style={{ ...styles.content, height, paddingHorizontal: 10 }}
          onContentSizeChange={(event) => {
            setHeight(Math.max(30, event.nativeEvent.contentSize.height));
          }}
          onChangeText={(newText) => setLocalContent(newText)}
          onSelectionChange={(event) => {
            // Show the card embedding interface if the curser is in front of an @ sign
            setEmbeddingCard(
              localContent[event.nativeEvent.selection.start - 1] == "@"
            );
            setCursorPosition(event.nativeEvent.selection.start);
            setLocalSelection(event.nativeEvent.selection);
          }}
          value={localContent}
          placeholder={
            content != ""
              ? null
              : "Incredible notes go here. Tap below for a reminder of how ReLearn Cards work."
          }
        />
        </View>
      ) : (
        <ParsedText
          cardDict={cardDict}
          isEditing={isEditing}
          text={localContent}
        />
      )}

      {editWarning && (
        <View
          style={{
            margin: 5,
            padding: 5,
            borderRadius: 5,
            backgroundColor: "crimson",
          }}
        >
          {" "}
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 14,
              color: "white",
            }}
          >
            {" "}
            WARNING: Someone else has edited this card. If you submit, your
            changes will overwrite theirs.
          </Text>
        </View>
      )}

      <Collapsible collapsed={!isEmbeddingCard}>
        <View style={{ borderWidth: 2, borderColor: "darkgray" }}>
          <Text> Select or create a card to embed. </Text>

          {isEmbeddingCard && (
            <CardsView
              groupId={groupId /* GET GROUP PATH FROM REF */}
              isEmbed={true}
              onPreviewTapped={onEmbedPreviewTapped}
            />
          )}
        </View>
      </Collapsible>

      {isPreview ? (
        <View />
      ) : (
        <View style={styles.footer}>
          {/* Left aligned things */}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignContent: "flex-end",
              justifyContent: "flex-start",
            }}
          >
            <Text style={{ fontSize: 16 }}>{authorName}</Text>
          </View>
          {/* Right aligned things */}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignContent: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            {!isEditing && (
              <LikeButton
                likedList={likedList}
                onPressList={(newList) =>
                  onLike(
                    {
                      id,
                      groupId,
                      tags,
                      content,
                      authorName,
                      authorId,
                      likedList,
                    },
                    newList
                  )
                }
              />
            )}
            {isEditing && (
              <TouchableOpacity onPress={toggleTutorial}>
                <Feather
                  name="help-circle"
                  size={24}
                  color="blue"
                  style={{ marginRight: 5 }}
                />
              </TouchableOpacity>
            )}
            <TouchableOpacity
              onPress={() => {
                LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
                setLocalTags(localTags);
                toggleEditing();
              }}
            >
              {isEditing ? (
                <Ionicons name="checkmark" size={24} color="blue" />
              ) : (
                <Feather name="edit-2" size={24} color="blue" />
              )}
            </TouchableOpacity>
          </View>
        </View>
      )}
      {isEditing && (
        <Collapsible collapsed={!showTutorial}>
          <OnboardingCards groupName={"this class"} />
        </Collapsible>
      )}
    </View>
  );
};

const PreviewCard = ({ onPress, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const tags = props.card.tags;

  return (
    <View>
      {!expanded ? (
        <TouchableOpacity
          onPress={() => {
            onPress();
            setExpanded(true);
          }}
        >
          <View style={styles.previewContainer}>
            <View style={styles.tags}>
              <Ionicons name={"chevron-down"} size={20} />
              {tags.map((tag, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      paddingRight: 10,
                      flexDirection: "row",
                      alignItems: "flex-end",
                      flexShrink: 1,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "500",
                        fontSize:
                          index == 0
                            ? 20
                            : 16 /* Make the first tag bigger as a title */,
                      }}
                    >
                      #{tag}
                    </Text>
                  </View>
                );
              })}
            </View>
            <Text style={styles.content} numberOfLines={3}>
              {props.card.content}
            </Text>
          </View>
        </TouchableOpacity>
      ) : (
        <View style={styles.previewContainer}>
          <BasicCard
            isPreview={true}
            onPreviewCollapse={() => setExpanded(false)}
            {...props}
          />
        </View>
      )}
    </View>
  );
};

BasicCard.propTypes = {
  /**
   * Should this card start as a preview (e.g.,) for rendering in a list?
   */
  isPreview: PropTypes.bool,
  /**
   * Should this card start in editing state?
   */
  isEditor: PropTypes.bool,
  /**
   * The data this card should be displaying
   */
  card: cardType.isRequired,
  /**
   * Optional preview expansion handler
   */
  onTapToExpand: PropTypes.func,
  /**
   * Optional submit editing handler
   */
  onSubmitEditing: PropTypes.func,
};

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <View role="alert">
      <Text>Something went wrong:</Text>
      <Text>{error.message}</Text>
    </View>
  );
}

export const Card = ({ isPreview, onSelect, ...props }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {isPreview ? (
        <PreviewCard
          onPress={() => {
            onSelect(props.card.id);
          }}
          {...props}
        />
      ) : (
        <BasicCard {...props} />
      )}
    </ErrorBoundary>
  );
};

BasicCard.defaultProps = {
  backgroundColor: null,
  isEditor: false,
  primary: false,
  size: "medium",
  onClick: undefined,
};

const styles = StyleSheet.create({
  borderlessButtonText: {
    fontSize: 16,
    color: "blue",
  },
  title: {
    marginBottom: 10,
    flex: 1,
    flexDirection: "row",
  },
  tags: {
    flexDirection: "row",
    marginBottom: 10,
    alignItems: "flex-end",
    flex: 1,
    flexWrap: "wrap",
  },
  content: { fontSize: 14, paddingHorizontal: 10 },
  footer: {
    paddingTop: 10,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  previewContainer: {
    padding: 10,
    borderRadius: 16,
    borderColor: "darkgray",
    borderWidth: 2,
    marginVertical: 10,
  },
  editorButton: {
    borderRadius: 5, 
    borderWidth: 1, 
    borderColor: "black", 
    padding: 3, 
    margin: 5
  },
});
