import React from "react";
import { Image, StyleSheet } from "react-native";

import { Images } from "../config";

export const Logo = ({ uri }) => {
  return <Image source={require("../assets/logo.png")} style={styles.image} />;
};

const styles = StyleSheet.create({
  image: {
    width: 200,
    height: 200,
    borderRadius: 40,
    overflow: "hidden",
  },
});
