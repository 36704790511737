/*
Takes in a list of cards to display
Takes in a list of tags to give the search bar
Takes in a function to run when a new tag is autocompleted
*/
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { View, TouchableOpacity, Text } from "react-native";

import { Card } from "./Card";
import { CardList } from "./CardList";
import { CardSearchBar } from "./CardSearchBar";
import { styles } from "./styles";
import { Feather } from "@expo/vector-icons";
import Collapsible from "react-native-collapsible";
import { searchTags } from "./utils";
import { useCards, updateCard } from "./cardsUtils";
import { getAuth } from "firebase/auth";
import { OnboardingCards } from "./OnboardingCards";
import { addPointsContributionToUser } from "./utils";

export const CardsView = React.memo(
  ({ groupId, isEmbed, onPreviewTapped, groupName }) => {
    const [creatingCard, setCreatingCard] = useState(false);
    const [searchedTags, setSearchedTags] = useState([]);
    const [newCardKey, setNewCardKey] = useState(0);

    //console.log("useCards: " + groupId + " " + searchedTags);
    const [cardDict, cardOrder, tagDoc, loading, error] = useCards(
      groupId,
      searchedTags,
      "dateCreated",
      0
    );

    const user = getAuth().currentUser;

    // 4. Why not just pass updateCard directly?

    const newCardSubmit = useCallback(async (card) => {
      const cardId = await updateCard(card);
      setCreatingCard(false);
      await addPointsContributionToUser(user.uid, {
        value: 1,
        card: cardId,
        group: groupId,
        reason: "card_created",
      });
      if (!!onPreviewTapped)
        onPreviewTapped("groups/" + groupId + "/cards/" + cardId);
      setNewCardKey(newCardKey + 1);
    }, [newCardKey]);

    const likeButtonPressed = useCallback(async (card, likedList) => {
      const sendPoints = likedList.indexOf(user.uid) > -1;
      const cardId = await updateCard({ ...card, likedList });
      if (sendPoints) {
        await addPointsContributionToUser(card.authorId, {
          value: 1,
          card: cardId,
          reason: "card_liked",
          likeFrom: user.uid,
        });
      }
    }, []);

    // Data is still loading case
    if (loading) {
      return (
        <View>
          <Text>Loading......</Text>
        </View>
      );
    }
    if (error) {
      return (
        <View>
          <Text>Error loading cards: {error}</Text>
        </View>
      );
    }

    const search = (tagsList) => {
      setSearchedTags(tagsList);
    };

    const blankCard = {
      groupId,
      tags: [],
      content: "",
      authorName: user.displayName,
      authorId: user.uid,
    };

    console.log("cardDict: ", cardDict);

    return (
      <View style={{ flex: 1, paddingVertical: 10 }}>
        <View
          style={{
            paddingHorizontal: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            zIndex: 100,
          }}
        >
          <View style={{ flex: 1 }}>
            <CardSearchBar
              tags={searchTags(tagDoc, searchedTags)}
              onSearch={search}
            />
          </View>
          <View
            style={[
              styles.buttonShadow,
              {
                height: 50,
                width: 50,
                marginLeft: 10,
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                setCreatingCard(!creatingCard);
              }}
            >
              <Feather name="plus" size={45} color="green" />
            </TouchableOpacity>
          </View>
        </View>
        <>
          {
            <Collapsible collapsed={!creatingCard}>
              <View style={styles.cardShadow}>
                <Card
                  key={newCardKey}
                  card={blankCard}
                  isPreview={false}
                  isEditor={creatingCard}
                  groupId={groupId}
                  onSubmitEditing={newCardSubmit}
                />
              </View>
            </Collapsible>
          }
          <CardList
            listKey={5}
            cardOrder={cardOrder}
            cardDict={cardDict}
            onSubmitEditing={updateCard}
            onLike={likeButtonPressed}
            onSelect={(id) => {
              console.log(id);
              if (!!onPreviewTapped) onPreviewTapped(id);
            }}
            isPreview={isEmbed}
          />
          {Object.keys(cardDict).length == 0 && (
            <OnboardingCards groupName={groupName} />
          )}
        </>
      </View>
    );
  }
);
