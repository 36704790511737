import React, { useEffect } from "react";
import { View, Button, Text, ActivityIndicator, TouchableOpacity } from "react-native";
import Participant from "./Participant";
import { Feather, Ionicons } from "@expo/vector-icons";
import { useRoom } from "use-twilio-video";

function Room({ token, identity, roomName, onDisconnected }) {
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    dominantSpeaker,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  } = useRoom();

  useEffect(() => {
    if (!room && token && roomName) {
      connectRoom({
        token,
        options: { name: roomName, dominantSpeaker: true },
      });
      return () => disconnectRoom();
    }
  }, [connectRoom, disconnectRoom, room, roomName, token]);

  if (error) return `Error: ${error.message}`;

  // connected
  if (room)
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        > 
          <TouchableOpacity 
            onPress={() => {
              disconnectRoom();
              onDisconnected();
            }}
          >
            <View style={{borderRadius: 30, backgroundColor: "#d42a2a", paddingLeft: 10, paddingBottom: 10, paddingRight: 12, paddingTop: 12, margin: 5}} >
              <Feather name="phone-missed" color="white" size={20}/>
            </View>
          </TouchableOpacity>
            <TouchableOpacity onPress={() => toggleCamera()}>
              {isCameraOn ? 
              <View style={{borderRadius: 30, borderColor: "black", borderWidth: 2, backgroundColor: "white", padding: 10, margin: 5}}>
                <Feather name="video-off" color="black" size={20}/>
              </View>
              :
              <View style={{borderRadius: 30, borderColor: "black", borderWidth: 2, backgroundColor: "white", padding: 10, margin: 5}} >
                <Feather name="video" color="dark-gray" size={20}/>
              </View>
              }
            </TouchableOpacity>
            
            <TouchableOpacity onPress={() => toggleMicrophone()}>
              {isMicrophoneOn ? 
              <View style={{borderRadius: 30, borderColor: "black", borderWidth: 2, backgroundColor: "white", paddingHorizontal: 10, paddingBottom: 9, paddingTop: 11, margin: 5}}>
                <Feather name="mic-off" color="black" size={20}/>
              </View>
              :
              <View style={{borderRadius: 30, borderColor: "black", borderWidth: 2, backgroundColor: "white", paddingHorizontal: 10, paddingBottom: 9, paddingTop: 11, margin: 5}} >
                <Feather name="mic" color="dark-gray" size={20}/>
              </View>
              }
            </TouchableOpacity>
        </View>
        {/*<Text>
          Local participant: {JSON.stringify(localParticipant?.identity)}
        </Text>
          <Participant participant={localParticipant} />
        <Text>
          Remote participants:{" "}
          {JSON.stringify(remoteParticipants.map((v) => v.identity))}
        </Text>
        <Text>
          Dominant speaker: {JSON.stringify(dominantSpeaker?.identity)}
        </Text>*/}
        <View style={{ flex: 1, flexDirection: "column", flexWrap: "wrap" }}>
          {[localParticipant, ...remoteParticipants].map((p) => (
            <View key={p.identity} style={{ flex: 1, minHeight: 200 }}>
              <Participant participant={p} />
            </View>
          ))}
        </View>
      </View>
    );

  return <ActivityIndicator size='large' />;
}

export default Room;
