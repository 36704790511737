import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  cardShadow: {
    //width: "50%",
    backgroundColor: "white",
    overflow: "hidden",
    padding: 10,
    marginVertical: 10,
    marginHorizontal: 10,
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: {
      height: 3,
      width: 0,
    },
  },
  embedCard: {
    // width: "50%",
    backgroundColor: "white",
    overflow: "hidden",
    paddingHorizontal: 10,
    //marginTop: 20,
    marginHorizontal: -5,
    borderRadius: 0,
    borderColor: "darkgray",
    borderLeftWidth: 2,
    /*
    borderTopWidth: 2,
    borderBottomWidth: 2,*/
  },
  buttonShadow: {
    // width: "50%",
    backgroundColor: "white",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: {
      height: 3,
      width: 0,
    },
  },
});
