import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { RootNavigator } from "./navigation/RootNavigator";
import { AuthenticatedUserProvider } from "./providers";
import { ErrorBoundary } from "react-error-boundary";
import { MenuProvider } from "react-native-popup-menu";
import FlashMessage from "react-native-flash-message";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <View role="alert">
      <Text>
        We're sorry, but ReLearn hit a speedbump. Please refresh the page.
        Error:
      </Text>
      <Text>{error.message}</Text>
    </View>
  );
}

const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <MenuProvider>
        <AuthenticatedUserProvider>
          <SafeAreaProvider>
            <RootNavigator />
            <FlashMessage />
          </SafeAreaProvider>
        </AuthenticatedUserProvider>
      </MenuProvider>
    </ErrorBoundary>
  );
};

export default App;
