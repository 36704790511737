import React, { useState } from "react";
import useConfig from "./hooks/useConfig";
import Lobby from "./components/Lobby";
import { View, Button } from "react-native";
import Room from "./components/Room";
import { getAuth } from "firebase/auth";
import { GradientButton } from "../GradientButton";
// import { useMyHook } from 'use-twilio-video'

const App = ({ groupId }) => {
  const [joined, setJoined] = useState(false);
  const {
    isLoading: configLoading,
    token,
    identity,
    roomName,
    error: configError,
    getToken,
  } = useConfig();

  // Loading
  if (configLoading) return "get config...";

  // error
  if (configError) return `Error: ${configError.message}`;

  // Lobby
  if (!joined || !token) {
    return (
    <View style={{padding: 10}}>
      <GradientButton
        title={"Join Study Lounge"}
        onPress={() => {
          getToken({
            identity: getAuth().currentUser.displayName,
            roomName: groupId,
          });
          setJoined(true);
        }}
      />
    </View>
    );
  }

  return (
    <Room
        token={token}
        identity={identity}
        roomName={roomName}
        onDisconnected={() => setJoined(false)}
      />
  );
};
export default App;
