import React, { useState } from "react";
import {
  View,
  FlatList,
  TextInput,
  Button,
  Image,
  Text,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import Modal from "react-native-modal";
import {
  getAuth,
  signOut,
  currentUser,
  getAdditionalUserInfo,
} from "firebase/auth";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { GradientButton, BoringButton } from "../GradientButton";

import { GroupDataWrapper } from "./GroupDataWrapper";
import { CreateGroupDialog } from "./CreateGroupDialog";
import { getEmailInfo } from "./utils";
import { auth } from "../../config";

export const GroupListModal = ({
  style,
  isVisible,
  handleClose,
  viewOnly,
  ...props
}) => {
  const emailInfo = getEmailInfo();
  const hasCollegeEmail = !!emailInfo.institutionName;

  const [userInput, setUserInput] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const [invalidCode, setInvalidCode] = useState(false);

  const [groupIds, setGroupIds] = useState([]);

  const [creatingGroup, setCreatingGroup] = useState(false);

  const navigation = useNavigation();

  const correctCode = (text) => {
    const code = text.replace(/\W/g, "").toUpperCase();
    setUserInput(code);
  };

  const findGroups = () => {
    if (userInput.length < 3 || !(userInput.match(/[A-Z][A-Z]+\d\d+/g))) {
      setInvalidCode(true);
      setHasSearched(false);
    } else {
      setInvalidCode(false);
      setCourseCode(userInput);
      console.log("Searching for groups by code ", userInput);
      setGroupIds([]);
      setHasSearched(true);
    }
  };

  const handleLogout = () => {
    signOut(auth).catch((error) => console.log("Error logging out: ", error));
  };

  const groupCreated = ({ id, color, name }) => {
    handleClose();
    navigation.navigate("GroupScreen", {
      name: name,
      groupId: id,
      color: color,
      members: [getAuth().currentUser.uid],
      leftGroup: false
    });
  };


  return (
    <Modal
      isVisible={isVisible}
      coverScreen={!viewOnly}
      backdropOpacity={viewOnly ? 0 : undefined}
    >
      <View style={styles.container}>
        {hasCollegeEmail ? (
          <View style={styles.modal}>
            <Text> Join a Group by Entering a Course Code </Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {hasCollegeEmail && (
                <Image
                  style={{ width: 15, height: 15, marginHorizontal: 5 }}
                  source={{
                    uri: "https://" + emailInfo.website + "/favicon.ico",
                  }}
                />
              )}
              {hasCollegeEmail && <Text>{emailInfo.institutionName}</Text>}
            </View>
            {invalidCode && <Text> That is not a valid code. </Text>}
            <View
              style={{
                maxWidth: "95%",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 10,
              }}
            >
              <View style={{marginRight: 5, alignItems: "center", justifyContent: "center"}}>
                <TextInput
                style={styles.modalInput}
                placeholderTextColor="#C7C7CD"
                placeholder="RLRN1110"
                onChangeText={correctCode}
                onSubmitEditing={findGroups}
                />
              </View>
              <GradientButton title="Search" onPress={findGroups} />
            </View>
            {!invalidCode && !!courseCode && (
              <GroupListResults
                collegeDomain={emailInfo.domain}
                courseCode={courseCode}
                handleClose={handleClose}
              />
            )}

            {hasSearched && (
            <View style={{marginBottom: 5}}>
              <GradientButton
                title="Create a new group"
                color="green"
                onPress={() => setCreatingGroup(true)}
              />
            </View>
            )}
            {creatingGroup && (
              <CreateGroupDialog
                isVisible={creatingGroup}
                courseCode={courseCode}
                closeDialog={() => {
                  setCreatingGroup(false);
                }}
                onGroupCreated={groupCreated}
              />
            )}

            <BoringButton title="Cancel" onPress={handleClose} />
          </View>
        ) : (
          <View style={{...styles.container, backgroundColor: "white"}}>
            <Text
              style={{
                fontSize: 24,
                fontWeight: "400",
                paddingBottom: 10,
                paddingTop: 5,
                textAlign: "center",
              }}
            >
              {" "}
              You must be signed in with a college email to use ReLearn{" "}
            </Text>
            <BoringButton title="Sign Out" onPress={handleLogout} />
          </View>
        )}
      </View>
    </Modal>
  );
};

const GroupListResults = ({
  collegeDomain,
  courseCode,
  handleClose,
}) => {
  const courseRef = doc(
    getFirestore(),
    "course-codes/" + collegeDomain + "/codes/" + courseCode
  );
  const [courseDoc, loadingCourse, courseError, courseSnapshot] =
    useDocumentData(courseRef);

  if (!!courseError) console.log(courseError);

  

  return (
    <>
      {loadingCourse && <ActivityIndicator />}
      {courseError && <Text>Error loading groups: {courseError.message}</Text>}
      {!loadingCourse && !courseError && !!courseDoc && (
        <FlatList
          data={
            courseDoc && courseDoc.groups ? Object.keys(courseDoc.groups) : []
          }
          renderItem={({ item }) => (
            <GroupDataWrapper key={item} groupId={item} onPress={handleClose} />
          )}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  modal: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    margin: 10,
    maxWidth: 800,
  },
  modalInput: {
    borderColor: "lightgray",
    color: "black",
    borderWidth: 1,
    borderRadius: 5,
    overflow: "hidden",
    width: "82%",
    height: "120%"
  },
});