import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  View,
  StyleSheet,
  Button,
  Text,
  useWindowDimensions,
} from "react-native";
import { signOut, getAuth, currentUser } from "firebase/auth";

import { auth } from "../config";
import { CardsView } from "../components/cards/CardsView";
import { JoinGroupDialog } from "../components/groups/JoinGroupDialog";
import { useNavigation } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import ChatScreen from "../components/chat/ChatScreen";
import { Ionicons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Collapsible from "react-native-collapsible";
import VideoChat from "../components/VideoChat";
// THEORY: For some reason changing the window size is causing this entire
// component to be remounted. or maybe all the components below.
// WHY?

const Tab = createBottomTabNavigator();

export const GroupScreen = (props) => {
  const navigation = useNavigation();
  const user = getAuth().currentUser;
  const { height, width } = useWindowDimensions();
  const [showVideoChat, setShowVideoChat] = useState(false);
  const isLargeScreen = width >= 768;
  const [showJoinPrompt, setShowJoinPrompt] = useState(
    props.route.params.members.indexOf(user.uid) < 0
  );
  const [joinedGroup, setJoinedGroup] = useState(
    props.route.params.members.indexOf(user.uid) >= 0
  );

  const dialogFinish = useCallback(joined => {
    if (!!joined) {
      console.log("User ", user.uid, " joined group ", props.route.params.name);
      setJoinedGroup(true);
    } else {
      console.log("User ", user.uid, " chose not to join the group.");
      navigation.navigate(
        "Home",
        {
          
        }
      );
    }
    setShowJoinPrompt(false);
  }, [user.uid, props.route.params.name]);

  // https://stackoverflow.com/questions/60453845/is-it-safe-to-usememo-for-jsx
  const Cards = useCallback(
    () => (
      <CardsView
        groupId={props.route.params.groupId}
        groupName={props.route.params.name}
      />
    ),
    [props.route.params.groupId, props.route.params.name]
  );
  const Chat = useCallback(
    () => (
      <View style={{flex: 1}}>
        <VideoChat groupId={props.route.params.groupId} />
        <ChatScreen groupId={props.route.params.groupId} />
      </View>
    ),
    [props.route.params.groupId]
  );

  useEffect(() => {
    if (!props.route.params.leftGroup) setShowJoinPrompt(props.route.params.members.indexOf(user.uid) < 0);
    setJoinedGroup(props.route.params.members.indexOf(user.uid) >= 0);
  }, [props.route.params.groupId, user.uid]);

  if (!showJoinPrompt && !joinedGroup)
    return (
      <View style={styles.container}></View>
    )
  if (showJoinPrompt)
    return (
      <View style={styles.container}>
        <JoinGroupDialog
          isVisible={showJoinPrompt}
          handleClose={dialogFinish}
          groupId={props.route.params.groupId}
          groupName={props.route.params.name}
        />
      </View>
    );
  else if (isLargeScreen)
    return (
      <View style={styles.container}>
        <View
          style={{
            flex: 1.5,
            padding: 10,
            borderColor: "lightgray",
            borderRightWidth: 1,
          }}
        >
          <Cards />
        </View>
        <Chat />
      </View>
    );
  else
    return (
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Tab.Screen
          name="Cards"
          component={Cards}
          options={{
            tabBarIcon: ({ focused }) => (
              <MaterialCommunityIcons
                name="cards"
                size={18}
                color={focused ? "tomato" : "black"}
              />
            ),
          }}
        />
        <Tab.Screen
          name="Chat"
          component={Chat}
          options={{
            tabBarIcon: ({ focused }) => (
              <Ionicons
                name="ios-chatbubble"
                size={18}
                color={focused ? "tomato" : "black"}
              />
            ),
          }}
        />
      </Tab.Navigator>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
});
