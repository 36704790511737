import React from "react";
import Modal from "react-native-modal";
import { View, Text, Button, StyleSheet } from "react-native";
import { addSelfToGroup } from "./utils";
import { useNavigation } from "@react-navigation/native";
import { GradientButton, BoringButton } from "../GradientButton";

export const JoinGroupDialog = ({
  isVisible,
  handleClose,
  groupId,
  groupName,
  ...props
}) => {
  return (
    <Modal isVisible={isVisible} style={{ flex: 1 }}>
      <View styles={styles.container}>
        <View style={styles.modal}>
          <Text style={{ textAlign: "center" }}>
            {" "}
            Join the group "{groupName}"?{" "}
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: 0,
              justifyContent: "center",
            }}
          >
           
            <View style={{ margin: 5 }}>
              <BoringButton
                onPress={() => handleClose(false)}
                title="No"
              />
            </View>
            <View style={{ margin: 5 }}>
              <GradientButton
                onPress={async () => {
                  await addSelfToGroup({ groupID: groupId });
                  handleClose(true);
                  
                }}
                title="Yes"
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  modal: {
    justifyContent: "center",
    backgroundColor: "white",
    flex: 1,
    borderRadius: 10,
    padding: 10,
    margin: 10,
  },
});
