import React, { useState } from "react";
import Modal from "react-native-modal";
import { View, Text, Button, TextInput, StyleSheet } from "react-native";
import { createGroup } from "./utils";
import { GradientButton, BoringButton } from "../GradientButton";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";

export const CreateGroupDialog = ({
  courseCode,
  isVisible,
  closeDialog,
  onGroupCreated,
}) => {

  const month = (new Date()).getMonth(); // 0 = jan, 11 = dec etc.

  const [selectedSemester, setSelectedSemester] = useState(
    month < 5 ? "SP" : (month < 7 ? "SU" : (month < 11 ? "FA" : "WI"))
  );
  const [name, setName] = useState("");
  const [noName, setNoName] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [showSemWarning, setShowSemWarning] = useState(false);
  const navigation = useNavigation();

  const numberIndex = courseCode.search(/\d+/g);
  const codeParts = {
    department: courseCode.slice(0, numberIndex),
    number: parseInt(courseCode.slice(numberIndex)),
  };

  const onSubmit = async () => {
    if (!name || name.length < 1) {
      setNoName(true);
      return;
    }

    setNoName(false);

    if (
      name.includes(courseCode) ||
      name.includes(codeParts.number.toString())
    ) {
      setInvalidName(true);
      return;
    }

    setInvalidName(false);

    if (!selectedSemester || selectedSemester == "none") {
        setShowSemWarning(true);
        return;
    }

    setShowSemWarning(false);

    console.log(selectedSemester)

    const idAndColor = await createGroup({
      name: name,
      semester: selectedSemester,
      courseCode: courseCode,
    });
    console.log("Created Group ", name, " with code ", courseCode);
    onGroupCreated({ ...idAndColor, name });
    closeDialog();
  };

  return (
    <Modal isVisible={isVisible}>
      <View style={styles.container}>
        <View style={styles.modal}>
          <Text> Create {courseCode} Group? </Text>
          {noName && (
            <Text>
              {" "}
              You forgot to write a name for your group in the box below.{" "}
            </Text>
          )}
          {invalidName && (
            <Text> The course name may not include the course code. </Text>
          )}
          <TextInput
            style={styles.modalInput}
            placeholderTextColor="#C7C7CD"
            placeholder="Intro to ReLearn"
            onChangeText={setName}
          />
          {showSemWarning && (
            <Text> You must select a semester for this group. </Text>
          )}
          <View style={{ flexDirection: "row", flex: 1, padding: 10 }}>
            {/* <Text>{"Select a semester: "}</Text> */}
            <Picker
              selectedValue={selectedSemester}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedSemester(itemValue)
              }}
            >
              <Picker.Item label="Select a semester" value="none" />
              <Picker.Item label="Fall" value="FA" />
              <Picker.Item label="Winter" value="WI" />
              <Picker.Item label="Spring" value="SP" />
              <Picker.Item label="Summer" value="SU" />
            </Picker>
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-around" }}
          >
            <View style={{marginRight: 10}}>
                <BoringButton title="Cancel" onPress={closeDialog} />
            </View>
            <GradientButton title="Create" onPress={onSubmit} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  modal: {
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    margin: 10,
    maxWidth: 800,
  },
  modalInput: {
    borderColor: "lightgray",
    color: "black",
    margin: 5,
    borderWidth: 1,
    borderRadius: 5,
    overflow: "hidden",
    width: "82%",
  },
});
