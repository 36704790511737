import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

export const GradientButton = ({
  textColor,
  title,
  onPress,
  gradientProps,
  borderStyle,
}) => {

  const defaultGradient = {
    colors: ["#FF512F", "#DD2476"],
    start: { x: 3, y: 0.5 },
    end: { x: 4, y: 0.5 },
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={{ borderRadius: 10, overflow: "hidden" }}>
        <LinearGradient
          style={{
            borderRadius: 10,
            justifyContent: "center",
            alignContent: "center",
            paddingVertical: 8,
            paddingHorizontal: 20,
            ...borderStyle,
          }}
          {...(gradientProps ? gradientProps : defaultGradient)}
        >
          <Text
            style={{
              color: textColor ? textColor : "white",
              textAlign: "center",
              fontSize: 16,
            }}
            numberOfLines={1}
          >
            {title}
          </Text>
        </LinearGradient>
      </View>
    </TouchableOpacity>
  );
};

export const BoringButton = (props) => {
  const whiteGradient = {
    colors: ["white", "white"],
    start: { x: 0, y: 0.5 },
    end: { x: 1, y: 0.5 },
  };

  return (
    <GradientButton
      textColor={"black"}
      borderStyle={{
        borderColor: "black",
        borderWidth: 1,
        paddingTop: 7,
        paddingBottom: 7,
      }}
      gradientProps={whiteGradient}
      {...props}
    />
  );
};
