import React, { useState, useRef } from "react";
import { Ionicons } from "@expo/vector-icons";
import { Animated, View, Text } from "react-native";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { getAuth } from "firebase/auth";
//import * as Haptics from 'expo-haptics';

const AnimatedIcon = Animated.createAnimatedComponent(Ionicons);

export default function LikeButton ({ item, style, currentMessage }) {
  const scale = new Animated.Value(1);

  const userID = getAuth().currentUser.uid;
  let likedList = currentMessage.liked;

  
  let opacity;
  let reverseOpacity;
  
  if (!!likedList && likedList.indexOf(userID) >= 0) {
    opacity = new Animated.Value(0);
    reverseOpacity = new Animated.Value(1);
  } else {
    opacity = new Animated.Value(1);
    reverseOpacity = new Animated.Value(0);
  }


  const like = () => {

    let alreadyLiked = !!likedList && likedList.indexOf(userID) >= 0;

    Animated.sequence([
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 50,
        useNativeDriver: true,
      }),
      Animated.timing(scale, {
        toValue: 1.2,
        duration: 150,
        useNativeDriver: true,
      }),
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 50,
        useNativeDriver: true,
      }),
      Animated.parallel([
        Animated.timing(scale, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(!alreadyLiked ? opacity : reverseOpacity, {
          toValue: 0,
          duration: 90,
          useNativeDriver: true,
        }),
        Animated.timing(!alreadyLiked ? reverseOpacity : opacity, {
          toValue: 1,
          duration: 90,
          useNativeDriver: true,
        }),
      ]),
    ]).start();
    //Haptics.selectionAsync();
    if (alreadyLiked) {
      //the value passed in should be a userID. this if-block should remove the current userID from the liked array
      updateDoc(currentMessage.firebaseDoc, {
        liked: arrayRemove(userID)
      })
    } else {
      //this else block should add the current userID to the liked array
      updateDoc(currentMessage.firebaseDoc, {
        liked: arrayUnion(userID)
      });
      //firebase.analytics().logEvent("like_chat")
    }
  };

  return (
    <View style={{ flexDirection: "row", bottom: 5 }}>
      <Text style={ currentMessage.user._id == getAuth().currentUser.uid ? { color: "white" } : { color: "gray", position: 'absolute', right: 30 } }>
        {(!!currentMessage.liked && currentMessage.liked.length > 0) ? currentMessage.liked.length : ""}
      </Text>
      <AnimatedIcon
        name={"ios-heart"}
        size={20}
        hitSlop={{ top: 10, bottom: 10, left: 20, right: 20 }}
        style={{
          ...style,
          position: "absolute",
          right: 0,
          paddingRight: 10,
          paddingLeft: 20,
          paddingBottom: 5,
          opacity: reverseOpacity,
          transform: [{ scale }],
        }}
        color="#B00000"
        onPress={() => like()}
      />
      <AnimatedIcon
        name={"ios-heart-outline"}
        size={20}
        hitSlop={{ top: 10, bottom: 10, left: 20, right: 20 }}
        style={{
          ...style,
          paddingRight: 10,
          paddingLeft: 20,
          opacity: opacity,
          transform: [{ scale }],
        }}
        color={currentMessage.user._id == getAuth().currentUser.uid ? "white" : "gray"}
        onPress={() => like()}
      />
    </View>
  );
};