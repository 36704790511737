import { getAuth, currentUser } from "firebase/auth";
import {
  getDoc,
  doc,
  getFirestore,
  runTransaction,
  arrayUnion,
  FieldValue,
  collection,
  deleteField,
  arrayRemove,
} from "firebase/firestore";
import { findByEmail } from "world-universities-search";

// Gets email domain, converts . to _ and checks whether it's
// a college email, also returning college name.

// Built to easily expand to non-university domains in the future.

// String: email -> Object: {domain, institutionName}
export function getEmailInfo() {
  var domain;
  var institutionName;
  var website;
  let email = getAuth().currentUser.email;
  const emailInfo = findByEmail(email)[0];
  if (!!emailInfo) {
    domain = email.split("@").slice(-1)[0].replace(/\./g, "_");
    institutionName = emailInfo.label;
    website = emailInfo.value;
  }

  //console.log("DOMAIN: " + domain + "UNI: " + institutionName);

  return { domain, institutionName, website };
}

export async function addSelfToGroup({ groupID }) {
  let uid = getAuth().currentUser.uid;

  const userSnap = await getDoc(doc(getFirestore(), "users", uid));
  const user = userSnap.data();


  /*if (!user.groups) {
      user.groups = {};
  }*/
  console.log("Ryan uid: ", uid);
  if (user.groups) {
    for (const [key, value] of Object.entries(user.groups)) {
      if (key == groupID) {
        console.log("User already in group");
        return;
      }
    }
  }

  // NOTE: ^ GROUP IS NOT IN USER'S LIST, BUT USER COULD STILL BE IN GROUP LIST DUE
  // TO A BUG. TODO: Check for that and make sure the two lists are in sync.
  // Note from the future: This is now less likely due to the batched transaction below.

  // User not yet in group, so add them.

  // Add group to user's group list
  console.log("starting group-join batch transaction");
  /*
  var userRef = doc(getFirestore(), "users", uid);
  console.log(uid, "<- Ryan's uid");
  updateDoc(userRef, {
    ['groups.' + groupID]: group.name
  });

  var groupRef = doc(getFirestore(), "groups", groupID);
  var d = (new Date()).getTime();

  console.log("Group writing to: ", groupID);
  console.log("at time: ", d);

  updateDoc(groupRef, {
    members: arrayUnion(uid),
    lastModified: d
  })
  */

  await runTransaction(getFirestore(), async (transaction) => {
    //var batch = firebase.firestore().batch();

    var userRef = doc(getFirestore(), "users", uid);
    var groupRef = doc(getFirestore(), "groups", groupID);

    const group = await transaction.get(groupRef);

    transaction.update(userRef, {
      ["groups." + groupID]: group.data().name,
    });


    var d = Date.now();

    transaction.update(groupRef, {
      members: arrayUnion(uid),
      lastModified: d,
    });
    // TODO: Add members collection like comment in createGroup.js
  });

  //await batch.commit();
  console.log("finished transaction");

  // Fire group joined analytics event
  /*firebase.analytics().logJoinGroup({
      group_id: groupID
  })*/

  // Register to this group's topic and make sure we have
  // prompted for notifications before
  /*if (enableNotifications) enableNotifications();*/

  return;
}

export async function createGroup({ name, semester, courseCode }) {
  const d = new Date();
  const uid = getAuth().currentUser.uid;
  const userDoc = doc(getFirestore(), "users", uid);
  const userSnap = await getDoc(userDoc);
  const user = userSnap.data();

  const emailInfo = getEmailInfo();

  const domain = !!emailInfo.institutionName ? emailInfo.domain : "non_college";

  const color =
    "hsl(" +
    360 * Math.random() +
    "," +
    (35 + 70 * Math.random()) +
    "%," +
    (70 + 10 * Math.random()) +
    "%)";

  let semesterWithYear;
  const year = d.getFullYear();

  if (semester == "WI") {
    if (d.getMonth() > 6) {
      semesterWithYear =
        semester +
        year.toString().slice(-2) +
        "-" +
        (year + 1).toString().slice(-2);
    } else {
      semesterWithYear =
        semester +
        (year - 1).toString().slice(-2) +
        "-" +
        year.toString().slice(-2);
    }
  } else {
    semesterWithYear = semester + year.toString().slice(-2);
  }

  const group = {
    name: name,
    domain: domain,
    courseCode: courseCode,
    color: color,
    dateCreated: d.getTime(),
    semester: semesterWithYear,
    lastModified: d.getTime(),
    moderators: [uid],
    members: [uid],
  };

  const groupDoc = doc(collection(getFirestore(), "groups"));
  const groupId = groupDoc.path.split("/").pop();

  const uploadGroup = await runTransaction(
    getFirestore(),
    async (transaction) => {
      const courseCodeDocRef = doc(
        getFirestore(),
        "course-codes",
        domain,
        "codes",
        courseCode
      );
      const courseCodeDoc = await getDoc(courseCodeDocRef);
      transaction.set(groupDoc, group, { merge: true });

      transaction.set(
        userDoc,
        {
          groups: {
            [groupId]: name,
          },
        },
        { merge: true }
      );

      console.log("courseCodeDoc: ", courseCodeDoc);
      console.log("does it exist?", courseCodeDoc.exists());

      transaction.set(
        courseCodeDocRef,
        {
          groups: { [groupId]: name },
        },
        { merge: true }
      );

      console.log("reached end of transaction");
    }
  );

  return { id: groupId, color: color };
}

export async function getShareLink(groupId, groupName) {
  let link = await (
    await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCSaw73DCQo2u1n8o3TlMOQEzBWZohSKpU",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: "https://links.relearn.fyi",
            link: "https://app.relearn.fyi/groups/" + groupId,
            androidInfo: {
              androidPackageName: "com.zaptrem.ReLearn",
            },
            iosInfo: {
              iosBundleId: "com.ReLearn.lecture",
              iosAppStoreId: "1498293224",
            },
            socialMetaTagInfo: {
              socialTitle: groupName + "Class Group",
              socialDescription:
                "Build your class community on ReLearn with self-organizing shared notes, chat, and video rooms.",
              socialImageLink: "https://relearn.fyi/logo.png",
            },
          },
          suffix: {
            option: "UNGUESSABLE",
          },
        }),
      }
    )
  ).json();

  if (link && link.shortLink) {
    return link.shortLink;
  } else {
    return Promise.reject("Link generation failed");
  }
}

export async function leaveGroup(groupId) {
  const uid = getAuth().currentUser.uid;

  console.log("starting group-leave batch transaction");

  const removeSelf = await runTransaction(
    getFirestore(),
    async (transaction) => {
      const userRef = doc(getFirestore(), "users", uid);
      const groupRef = doc(getFirestore(), "groups", groupId);

      transaction.update(userRef, {
        ["groups." + groupId]: deleteField(),
      });

      const d = new Date().getTime();

      transaction.update(groupRef, {
        members: arrayRemove(uid),
        lastModified: d,
      });
    }
  );

  if (removeSelf) {
    console.log("transaction returned: ", removeSelf);
  }

  console.log("transaction finished");

  return;
}

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );
