import React, { useEffect, useRef } from 'react'

export default function VideoTrack ({ track }) {
  const ref = useRef()

  useEffect(() => {
    if (track) {
      const el = ref.current
      track.attach(el)

      return () => {
        track.detach(el)
      }
    }
  }, [track])

  return <video style={{ borderWidth: 10, position: "absolute", height: "100%", width: "100%" }} ref={ref} />
}
