import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config";

const API_URL = `/video/token`;
const addMessage = httpsCallable(functions, "getVideoToken");
const INITIAL_STATE = {
  isLoading: false,
  error: null,
  token: null,
  identity: null,
  roomName: null,
};
/**
 * get token to connect to a room
 */
export default function useConfig() {
  const [config, setConfig] = useState(INITIAL_STATE);

  const getToken = ({ identity, roomName }) => {
    if (roomName) {
      console.log("Getting token for groupId: ", roomName)
      addMessage({ groupID: roomName }).then((result) => {
        console.log("Token Object: ", result);
        if (result.data.error) {
          console.log("Server error");
          alert(
            "Something went wrong getting ready to join that Lounge: " +
              result.data.error.message
          );
          setConfig((c) => ({ ...c, isLoading: false, error }));
          return;
        }
        setConfig({
          isLoading: false,
          token: result.data.jwt,
          identity,
          roomName,
        });
      });
    }
  };
  return { ...config, getToken };
}
