import React, { useCallback } from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";

import { Colors } from "../config";

export const Button = ({
  children,
  onPress,
  activeOpacity = 0.3,
  borderless = false,
  title,
  style,
}) => {
  const _style = useCallback(({ pressed }) => [
    style,
    { opacity: pressed ? activeOpacity : 1 },
  ]);

  if (borderless) {
    return (
      <TouchableOpacity onPress={onPress} style={_style}>
        <Text style={styles.borderlessButtonText}>{title}</Text>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity onPress={onPress} style={_style}>
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  borderlessButtonText: {
    fontSize: 16,
    color: Colors.blue,
  },
});
