import React from "react";
import {View, Text, TouchableOpacity, StyleSheet} from "react-native";

export const Tag = ({ text, onPress, style, textStyle, pressable }) => {
    return (
        <TouchableOpacity onPress={onPress} disabled={!pressable}>
            <View style={style ? style : {
                  marginLeft: 10,
                  flex: 1,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 5,
                  backgroundColor: "#E3E8EF",
                  alignItems: "flex-end",
                  flexShrink: 1,
                }}>
                <Text style={textStyle ? textStyle : {
                    color: "black",
                    fontSize: 12,
                  }}>
                      {text}
                </Text>
            </View>
        </TouchableOpacity>
    )
}
