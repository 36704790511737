import React, { useCallback, useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { Feather, Ionicons } from "@expo/vector-icons";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { showMessage, hideMessage } from "react-native-flash-message";
import Clipboard from "@react-native-clipboard/clipboard";

import { getShareLink, leaveGroup, isSafari } from "./utils";
import { getAuth } from "firebase/auth";

export const GroupContextMenu = ({ id, name }) => {
  const navigation = useNavigation();
  
  const getInviteLink = () => {
    const link = getShareLink(id, name);
    if (isSafari)
      navigator.clipboard.write([new ClipboardItem({ "text/plain": link })]);
    else
      (async () => {
        const linkText = await link;
        Clipboard.setString(linkText);
      })();
    showMessage({
      message: "Copied invite link to clipboard!",
      floating: true,
      style: {
        borderRadius: 10,
        justifyContent: "center",
        maxWidth: 400,
        alignSelf: "center",
        alignItems: "center",
        marginHorizontal: 10,
        textAlign: "center",
      },
      textStyle: {
        color: "white",
      },
      backgroundColor: "green",
      icon: (props) => (
        <Feather
          name="check-circle"
          color="white"
          size={20}
          style={{ marginRight: 5 }}
        />
      ),
      position: "top",
      duration: 3000,
    });
  }

  const removeFromGroup = async () => {
    console.log(
        "user " +
          getAuth().currentUser.uid +
          " left group " +
          name +
          ", " +
          id
      );
      const activeParams = navigation.getCurrentRoute().params;
      if (
        activeParams &&
        activeParams.groupId &&
        activeParams.groupId == id
      ) {
        navigation.setParams({
          groupId: "exited group",
          members: ["user uuid", "not in", "this array"],
          leftGroup: true,
        });
        navigation.navigate("Home", {});
      }
      await leaveGroup(id);
      showMessage({
        message: "Left group successfully!",
        floating: true,
        style: {
          borderRadius: 10,
          justifyContent: "center",
          maxWidth: 400,
          alignSelf: "center",
          alignItems: "center",
          marginHorizontal: 10,
          textAlign: "center",
        },
        textStyle: {
          color: "white",
        },
        backgroundColor: "dodgerblue",
        icon: (props) => (
          <Feather
            name="log-out"
            color="white"
            size={20}
            style={{ marginRight: 5 }}
          />
        ),
        position: "top",
        duration: 3000,
      });
  }

  return (
    <Menu>
      <MenuTrigger>
        <Feather name="more-vertical" color="white" size={20} />
      </MenuTrigger>
      <MenuOptions
        customStyles={{
          optionsContainer: {
            borderRadius: 10,
          },
        }}
      >
        <MenuOption onSelect={getInviteLink}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 2,
              borderBottomColor: "lightgray",
              marginHorizontal: 5,
              marginTop: 5,
              paddingBottom: 8,
            }}
          >
            <Text>Invite others</Text>
            <Feather name="user-plus" color="black" size={20} />
          </View>
        </MenuOption>
        <MenuOption
          onSelect={removeFromGroup}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginHorizontal: 5,
              marginBottom: 5,
            }}
          >
            <Text>Leave group</Text>
            <Feather name="log-out" color="black" size={20} />
          </View>
        </MenuOption>
      </MenuOptions>
    </Menu>
  );
};
