import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Animated, View, Text } from "react-native";
import { getAuth } from "firebase/auth";

const AnimatedIcon = Animated.createAnimatedComponent(Ionicons);

export const LikeButton = ({ likedList, onPressList, style }) => {
  const user = getAuth().currentUser;
  const scale = new Animated.Value(1);

  let opacity;
  let reverseOpacity;

  if (!!likedList && likedList.indexOf(user.uid) >= 0) {
    opacity = new Animated.Value(0);
    reverseOpacity = new Animated.Value(1);
  } else {
    opacity = new Animated.Value(1);
    reverseOpacity = new Animated.Value(0);
  }

  const like = () => {
    let alreadyLiked = !!likedList && likedList.indexOf(user.uid) >= 0;

    Animated.sequence([
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 50,
        useNativeDriver: true,
      }),
      Animated.timing(scale, {
        toValue: 1.2,
        duration: 150,
        useNativeDriver: true,
      }),
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 50,
        useNativeDriver: true,
      }),
      Animated.parallel([
        Animated.timing(scale, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(!alreadyLiked ? opacity : reverseOpacity, {
          toValue: 0,
          duration: 90,
          useNativeDriver: true,
        }),
        Animated.timing(!alreadyLiked ? reverseOpacity : opacity, {
          toValue: 1,
          duration: 90,
          useNativeDriver: true,
        }),
      ]),
    ]).start();

    if (alreadyLiked) {
      onPressList(likedList.filter((value) => value != user.uid));
    } else {
      if (!likedList){
        onPressList([user.uid])
      } else {
        onPressList([...likedList, user.uid]);
      }
    }

    // TODO: Add analytics?
  };

  return (
    <View style={{ flexDirection: "row", bottom: 0, paddingRight: 0 }}>
      <Text
        style={
           { color: "gray", position: "absolute", right: 30 }
        }
      >
        {!!likedList && likedList.length > 0
          ? likedList.length
          : ""}
      </Text>
      <AnimatedIcon
        name={"ios-heart"}
        size={20}
        hitSlop={{ top: 10, bottom: 10, left: 20, right: 20 }}
        style={{
          ...style,
          position: "absolute",
          right: 0,
          paddingRight: 10,
          paddingLeft: 20,
          paddingBottom: 5,
          opacity: reverseOpacity,
          transform: [{ scale }],
        }}
        color="#B00000"
        onPress={() => like()}
      />
      <AnimatedIcon
        name={"ios-heart-outline"}
        size={20}
        hitSlop={{ top: 10, bottom: 10, left: 20, right: 20 }}
        style={{
          ...style,
          position: "absolute",
          right: 0,
          paddingRight: 10,
          paddingLeft: 20,
          paddingBottom: 5,
          opacity: opacity,
          transform: [{ scale }],
        }}
        color={"gray"}
        onPress={() => like()}
      />
    </View>
  );
};
