// types/index.js
import { shape, number, string, oneOf, arrayOf } from 'prop-types';

/*
PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
      content: PropTypes.string.isRequired,
      authorName: PropTypes.string.isRequired,
      authorId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
  })

*/

export const GroupItemType = shape({
  id: string.isRequired,
  courseCode: string,
  name: string.isRequired,
  color: string.isRequired,
  members: arrayOf(string.isRequired),
  loungePopulation: number,
  loungeCapacity: number,

});