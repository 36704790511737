import React from "react";
import AudioTrack from "./AudioTrack";
import VideoTrack from "./VideoTrack";
import { useTrack } from "use-twilio-video";
import { View, Text } from "react-native";

function Participant({ participant }) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({
    participant,
  });

  return (
    <div style={{ flex: 1 }}>
      {videoOn && <VideoTrack track={videoTrack} />}
      <br />
      {audioOn && <AudioTrack track={audioTrack} />}
      <View
        style={{
          position: "absolute",
          opacity: 0.8,
          bottom: 2,
          alignSelf: "center",
          background: "dodgerblue",
          borderRadius: 10,
          padding: 5,
        }}
      >
        <Text style={{ color: "white" }}>{participant.identity}</Text>
        {!audioOn && <Text style={{ color: "white" }}>Muted</Text>}
        {!videoOn && <Text style={{ color: "white" }}>Camera Off</Text>}
      </View>
    </div>
  );
}

export default Participant;
