import React, { useCallback, useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";

import { GroupItemType } from "./types";
import { GroupContextMenu } from "./GroupContextMenu";

/**
 * A GroupItem button that contains information about a given
 * group
 */
export const GroupItem = ({
  groupItem: {
    id,
    courseCode,
    name,
    color,
    members,
    dateCreated,
    semester,
    loungePopulation,
    loungeCapacity,
  },
  menuButton,
  style,
  onPress,
  ...props
}) => {
  const navigation = useNavigation();
  const memberCount = members.length;
  if (!menuButton && (Date.now() - dateCreated) > 10370000000) { // four months in millliseconds
    return (<View/>)
  }
  return (
    <TouchableOpacity
      style={[styles.itemContainer, { backgroundColor: color }]}
      onPress={() => {
        navigation.navigate("GroupScreen", {
          /*groupPath: this.state.path,*/
          name: name,
          groupId: id,
          color: color,
          members: members,
          leftGroup: false,
        });
        onPress();
      }}
    >
      <View
        style={{
          ...style,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Text style={styles.itemCode}>{courseCode}</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.itemCode}>
            {" "}
            {memberCount}
            {memberCount > 1 ? " members" : " member"}{" "}
          </Text>
          {loungePopulation > 0 && (
            <Text style={[styles.itemCode, { fontWeight: 900 }]}>
              {`/ ${loungePopulation} in Lounge`}
            </Text>
          )}
        </View>
      </View>
      <View style={{ ...style }}>
        <View
          style={{
            ...style,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text style={styles.itemName}>{semester ? semester + ": " : ""}{name}</Text>
          <View style={{ ...style, paddingTop: 5, alignSelf: "flex-end" }}>
            {menuButton ? <GroupContextMenu id={id} name={name} /> : <View />}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

GroupItem.propTypes = {
  /**
   * The data this GroupItem should be displaying
   */
  groupItem: GroupItemType.isRequired,
  /**
   * Called when someone taps the item.
   */
  onPress: PropTypes.func,
};

GroupItem.defaultProps = {
  GroupItem: {},
  onPress: console.log,
};

const styles = StyleSheet.create({
  itemContainer: {
    borderRadius: 5,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  itemName: {
    fontSize: 20,
    fontWeight: "600",
    color: "#fff",
  },
  itemDataWrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse",
  },
  itemCode: {
    fontWeight: "600",
    fontSize: 12,
    color: "#fff",
  },
});
