import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { GroupsDrawer } from "./GroupsDrawer";
import { HomeScreen } from "../screens";

const Stack = createStackNavigator();

// Temporary. We might want this stack when we add Settings/etc.

/*
export const AppStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen name="GroupsDrawer" component={GroupsDrawer} />
    </Stack.Navigator>
  );
};
*/
export const AppStack = GroupsDrawer;

