import * as React from "react";
import {
  useWindowDimensions,
  View,
  Image,
  Text,
  Button,
  StyleSheet,
} from "react-native";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import * as Progress from "react-native-progress";
import { GradientButton, BoringButton } from "../components/GradientButton";
import { firestore } from "../config";

import { auth } from "../config";

import { GroupDataWrapper } from "../components/groups/GroupDataWrapper";
import { HomeScreen } from "../screens";
import { GroupScreen } from "../screens/GroupScreen";
import { GroupLoading } from "../screens/GroupLoading";
import { AddGroupScreen } from "../screens/AddGroupScreen";
import { GroupListModal } from "../components/groups/GroupListModal";

const Drawer = createDrawerNavigator();

/*
  TODO:
  List groups
  Custom items
  ReLearn Logo at the top
  Add Group Button
*/

/**
 * The GroupsDrawer contains GroupItem buttons and
 * pages for every group the user is in. It also contains
 * an add group button.
 */
export const GroupsDrawer = () => {
  const { height, width } = useWindowDimensions();
  const isLargeScreen = width >= 950;

  return (
    <Drawer.Navigator
      defaultStatus="open"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        headerShown: isLargeScreen ? false : true,
        drawerType: isLargeScreen ? "permanent" : "back",
        drawerStyle: { flex: 1, maxWidth: 325, height: height },
        overlayColor: "transparent",
      }}
    >
      <Drawer.Screen name="Home" component={HomeScreen} />
      <Drawer.Screen name="GroupLoading" component={GroupLoading} />
      <Drawer.Screen
        name="GroupScreen"
        options={({ route }) => ({
          title: route.params?.name,
          headerStyle: {
            backgroundColor: route.params?.color,
            /*headerTintColor: TODO: SET WITH CONTRAST FUNCTION*/
          },
        })}
        getId={({ params }) => {
          console.log("PARAMS: ", params);
          return params?.groupId;
        }}
        component={GroupScreen}
      />
      <Drawer.Screen name="Add Group" component={AddGroupScreen} />
    </Drawer.Navigator>
  );
};
/*
const DynamicScreens = ({groupIds}) => {
  return groupIds.map((groupId) => (
    <Drawer.Screen key={groupId} name={groupId} component={HomeScreen} />
  ));
}
*/

const CustomDrawerContent = (props) => {
  const user = getAuth().currentUser;
  const userId = user.uid;
  console.log("USER ID: ", userId);
  const [userData, loading, error, snapshot] = useDocumentData(
    doc(firestore, "users", userId)
  );
  const [joiningGroup, setJoiningGroup] = React.useState(false);
  const handleLogout = () => {
    signOut(auth).catch((error) => console.log("Error logging out: ", error));
  };

  let groupIds = [];
  let points = 0;
  const heroThreshold = 50;
  if(error){
    console.log("ERROR GETTING GROUPS: ", error);
  }
  if (!loading && !!userData) {
    if (!!userData.groups) {
      groupIds = Object.keys(userData.groups);
    }
    
      
    if (!!userData.points) {
      userData.points.forEach(({ value }) => {
        points += value;
      });
    }

    /*if (groupIds.indexOf("W3RmtQC5qzqXEYdlbi0j") < 0) {
      groupIds.push("W3RmtQC5qzqXEYdlbi0j");
      console.log("Manually added Latin 6214 to the groups list from id: W3RmtQC5qzqXEYdlbi0j");
    }*/
    //console.log("list of groupIds for the drawer: ", groupIds);
  }

  /** if it's a new user, for whom there is no user doc, we initialize it */
  if (!loading && !userData) {
    setDoc(doc(firestore, "users", userId), {
      avatarUrl: user.photoURL,
      displayName: user.displayName,
      email: user.email,
      groups: {},
      providerData: [{}],
    }, {merge: true})
  }
  console.log("GROUP IDS: ", groupIds);
  console.log("USER DATA: ", userData)

  React.useEffect(() => {
    // Go to onboarding if no groups.
    //console.log("POGGING");
    if (!loading && groupIds.length == 0) {
      props.navigation.closeDrawer();
    }
  }, [groupIds]);

  return (
    <DrawerContentScrollView style={{ flex: 1 }} {...props}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 1
        }}
      >
        <Image
          style={{
            marginLeft: 10,
            height: 40,
            width: 40,
            borderRadius: 40,
            overflow: "hidden",
            resizeMode: "contain",
          }}
          source={{uri: user.photoURL}}
        />
        <View style={{ marginLeft: 0, marginRight: 0, flex: 3, padding: 10 }}>
          <Text style={{ textAlign: "center", fontWeight: "500" }}>
            {user.displayName}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            marginRight: 10,
            flex: 5,
          }}
        >
          <Progress.Bar
            progress={points / heroThreshold}
            color={points >= heroThreshold ? "darkgreen" : "blue"}
          />
          <View
            style={{ width: 150, flexGrow: 1, flex: 1, flexDirection: "row" }}
          >
            {points >= heroThreshold ? (
              <Text style={{ fontWeight: "bold" }}>
                ReLearn Hero: {points}{" "}
              </Text>
            ) : (
              <Text>
                Score: {points} / {heroThreshold} to join ReLearn Heroes
              </Text>
            )}
          </View>
        </View>
      </View>
      {groupIds &&
        groupIds.sort().map((groupId) => {
          return (
            <GroupDataWrapper
              groupId={groupId}
              key={groupId}
              menuButton
              onPress={() => {
                /* TODO: LIST SHOULD CLOSE EVEN WHEN NAVVING TO SAME LOCATION*/
                props.navigation.closeDrawer();
              }}
            />
          );
        })}
      <View style={{ flexDirection: "row", flex: 1 }}>
        <View style={{ flex: 1, margin: 5 }}>
          <BoringButton
            onPress={handleLogout}
            title="Sign Out"
          />
          {/* <Button title="Sign Out" onPress={handleLogout} /> */}
        </View>
        <View style={{ flex: 1, margin: 5 }}>
          <GradientButton
            onPress={() => setJoiningGroup(true)}
            title="Join a Group"
          />
          {/* <Button title="Join a Group" onPress={() => setJoiningGroup(true)} /> */}
          {joiningGroup && (
            <GroupListModal
              isVisible={joiningGroup}
              handleClose={() => setJoiningGroup(false)}
              style={{}}
            />
          )}
        </View>
      </View>
      {/*<DrawerItemList {...props} />*/}
    </DrawerContentScrollView>
  );
};


