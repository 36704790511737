import React, { useState, useEffect, useMemo } from "react";
import {
  useDocument,
  useCollectionData,
  useCollection,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  addDoc,
  doc,
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  runTransaction,
} from "firebase/firestore";
import { updateTags, searchTags, searchCards } from "./utils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config";
import { card } from "./types";

export function useCards(groupId, withTags, sortBy, returnNumber) {
  console.log("running useCards");
  const [cardsDoc, loading, error, cardsDocSnapshot] = useDocumentData(
    doc(getFirestore(), "groups", groupId, "cards", "cardsDoc")
  );

  var resultsOrder = [];
  var modifiedCardsDict = {};
  var tagsDoc = updateTags({});

  // Don't try to sort results if there are none.
  if (!loading && !error && cardsDoc) {
    // Inject id and groupId into cards
    modifiedCardsDict = Object.fromEntries(
      Object.entries(cardsDoc.cards).map(([id, cardObj]) => [
        id,
        {
          ...cardObj,
          id: id,
          groupId,
        },
      ])
    );

    // if tagDoc info exists replace our blank local tagDoc
    if (cardsDoc.cardDict && cardsDoc.tagDict)
      tagsDoc = {
        cardDict: cardsDoc.cardDict,
        tagDict: cardsDoc.tagDict,
      };
    // Get all modified card objects that contain given tags.
    var results = searchCards(tagsDoc, withTags).map(
      (cardId) => modifiedCardsDict[cardId]
    );

    // Sort by selected mode.
    switch (sortBy) {
      case "dateEdited":
        results = results.sort((a, b) => b.dateEdited - a.dateEdited);
        break;
      case "dateCreated":
        results = results.sort((a, b) => b.dateCreated - a.dateCreated);
        break;
      default:
        results = results.sort((a, b) => b.dateCreated - a.dateCreated);
    }

    // Only return the number of cards the hook user wants (for pagination)
    if (returnNumber > 0) results = results.slice(0, returnNumber + 1);

    // Convert back to card ids.
    resultsOrder = results.map((cardObj) => cardObj.id);
  }

  var resArray = [modifiedCardsDict, resultsOrder, tagsDoc, loading, error];
  return useMemo(function () {
    return resArray;
  }, resArray);
}

export async function updateCard(cardObj) {
  if (!cardObj.groupId) console.error("YO I TOLD YOU THIS BETTER HAVE groupId");

  var cardId = "";
  await runTransaction(getFirestore(), async (transaction) => {
    const cardsDocRef = doc(
      getFirestore(),
      "groups",
      cardObj.groupId,
      "cards",
      "cardsDoc"
    );
    const cardsDocSnap = await transaction.get(cardsDocRef);
    let cardsDoc = cardsDocSnap.exists()
      ? cardsDocSnap.data()
      : {
          cards: {},
          ...updateTags({}),
        };

    console.log("CARDS DOC:", cardsDoc);
    console.log("CARD OBJ:", cardObj);

    cardId = cardObj.id;
    // if no ID, generate a new one (here we just steal Google's ID creation func)
    // and add date created
    if (!cardObj.id) {
      cardId = doc(
        collection(getFirestore(), "groups", cardObj.groupId, "cards")
      )
        .path.split("/")
        .pop();
      cardObj.dateCreated = Date.now();

      // this is a new card so record analytics
      console.log("logging add_card");
      logEvent(analytics, "add_card", { name: cardObj.tags[0], groupId: cardObj.groupId });
    }else{
      //not new? log analytics
      console.log("logging edit_card");
      logEvent(analytics, "edit_card", { name: cardObj.tags[0], groupId: cardObj.groupId });
    }

    var tagsDoc = {
      cardDict: cardsDoc.cardDict,
      tagDict: cardsDoc.tagDict,
    };

    // console.log("Old tagsDoc: ", tagsDoc);
    // console.log("ID : ", cardId);
    // console.log("tagsArray: ", cardObj.tags);
    // Update cardDog
    tagsDoc = updateTags({
      tagsDocObject: tagsDoc,
      cardId: cardId,
      tagsArray: cardObj.tags,
    });
    delete cardObj.groupId;
    delete cardObj.id;
    // Set dateEdited.
    card.dateEdited = Date.now();
    cardsDoc.cards[cardId] = cardObj;

    cardsDoc = {
      ...cardsDoc,
      ...tagsDoc,
    };
    console.log("SETTING DOC:");
    transaction.set(cardsDocRef, cardsDoc, { merge: true });
  });
  return cardId;
}
// There will be a future edit card function
// takes groupId, cardId, cardObj -> edits cards, cardDict, and tagDict
// based on changes
// array union tag/card adds, array remove, tag/card removes.
// If no cardId, generate one
// Also add last edited date and date created
