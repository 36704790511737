import React, { useCallback, useMemo } from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  View,
  KeyboardAvoidingView,
} from "react-native";
import { styles } from "./styles";
import { Card } from "./Card";

/**
 * A CardList is a list of Cards
 */
export const CardList = ({
  isPreview,
  cardOrder,
  cardDict,
  onSubmitEditing,
  onSelect,
  onLike,
  ...props
}) => {
  if (cardOrder.length === 0)
    return (
      <Text style={{ alignText: "center", fontSize: 20 }}>
      </Text>
    );
  else {
    const selectCallback = useCallback(onSelect);
    const submitCallback = useCallback(onSubmitEditing);

    return (
      <KeyboardAvoidingView style={{ flex: 1 }}>
        <FlatList
          data={cardOrder}
          keyExtractor={(item) => item}
          renderItem={({ item }) => {
            //console.log("CARD: ", item);
            return (
              <View style={styles.cardShadow}>
                <Card
                  card={cardDict[item]}
                  cardDict={cardDict}
                  isPreview={isPreview}
                  isEditor={false}
                  onLike={onLike}
                  onSelect={selectCallback}
                  onSubmitEditing={submitCallback}
                  {...props}
                />
              </View>
            );
          }}
          //contentContainerStyle={styles.cardShadow}
        />
      </KeyboardAvoidingView>
    );
  }
};

CardList.defaultProps = {
  cards: [],
  onSubmitEditing: console.log,
};
