import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  setLogLevel,
  getFirestore,
  enableIndexedDbPersistence,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { Platform } from "react-native-web";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import Constants from "expo-constants";

// add firebase config
const firebaseConfig = {
  apiKey: Constants.manifest.extra.apiKey,
  authDomain: Constants.manifest.extra.authDomain,
  projectId: Constants.manifest.extra.projectId,
  storageBucket: Constants.manifest.extra.storageBucket,
  messagingSenderId: Constants.manifest.extra.messagingSenderId,
  appId: Constants.manifest.extra.appId,
  measurementId: Constants.manifest.extra.measurementId,
};

// initialize firebase
const app = initializeApp(firebaseConfig);

// initialize auth
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, 'us-east4');
const storage = getStorage(app);
const rtdb = getDatabase(app);
const analytics = getAnalytics(app);


if (__DEV__ && !(Platform.OS === "iOS") && false) {
  const origin =
    Constants.manifest.debuggerHost?.split(":").shift() || "localhost";

  console.log("Switching to local Firebase instance...", origin);

  connectAuthEmulator(auth, `http://${origin}:9099/`);
  connectFirestoreEmulator(firestore, origin, 8080);
  connectFunctionsEmulator(functions, origin, 5001);
  connectStorageEmulator(storage, origin, 9199);
  connectDatabaseEmulator(rtdb, origin, 9000);
} else {
  /*enableIndexedDbPersistence(getFirestore()).catch((err) => {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      console.log(
        "DB Persist ERR: Multiple tabs open, persistence can only be enabled in one tab at a a time."
      );
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log(
        "DB Persist ERR: The current browser does not support persistence"
      );
    }
    console.log("err");
  });*/
}

setLogLevel("error");

export { auth, firestore, functions, storage, rtdb, analytics };
