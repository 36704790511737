import React, { useState, useContext, useEffect } from 'react';
import {
  GiftedChat,
  Bubble,
  Send,
  SystemMessage,
  Actions,
  ActionsProps,
} from 'react-native-gifted-chat';
import { ActivityIndicator, View, StyleSheet, Image, KeyboardAvoidingView, TouchableOpacity, Text, Button } from 'react-native';
import { getAuth } from "firebase/auth";
import { addDoc, getDocs, collection, query, where, orderBy, limit, startAfter, getFirestore, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";



import * as ImagePicker from 'expo-image-picker';
import uuid from "./uuid";

import { Ionicons } from '@expo/vector-icons';
import LikeButton from './Old_LikeButton';
export default class ChatScreen extends React.Component {

  constructor(props) {
    super(props);
    // this.props.chatPath leads to chat document


    // Don't call this.setState() here! 
    this.state = {
      limit: 15,
      liveChats: [],
      oldChats: [],
      messages: [],
      currentUser: getAuth().currentUser,
      chatPath: this.props.chatPath

    }
    console.log("New Chat Screen pointed at: ");

    const chatsRef = collection(getFirestore(), "groups", this.props.groupId, "generalChat")
    const chatsQuery = query(chatsRef, orderBy("createdAt", "desc"), limit(this.state.limit));
    this.firestoreUnsubscribe = onSnapshot(chatsQuery, this.onResult.bind(this), this.onError.bind(this));
    
    
    /*this.props.chatPath
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .onSnapshot(this.onResult.bind(this), this.onError.bind(this));*/


  }

  onResult(CollectionSnapshot) {
    console.log('Got live chats snapshot');

    let lastDoc;
    const messages = CollectionSnapshot.docs.map(doc => {
      const firebaseData = doc.data();
      lastDoc = doc;
      const data = {
        _id: doc.id,
        firebaseDoc: doc.ref,
        text: '',
        createdAt: new Date().getTime(),
        ...firebaseData
      };
      if (!firebaseData.system) {
        data.user = {
          ...firebaseData.user,
        };
      }

      return data;
    });

    if (!this.state.lastVisible) this.setState({
      lastVisible: lastDoc
    })

    // Determine how many messages have been received since we last checked.
    // This way we know how many to put in oldChats
    // Checks from beginning of new list until it finds a message that wasn't there before;
    var numNewMessages = 0;
    if (this.state.liveChats[0] && this.state.liveChats.length == 15) messages.some(message => {
      if (this.state.liveChats[0]._id == message._id) return true;
      numNewMessages++;
      console.log(this.state.liveChats[0]._id + "!=" + message._id);
      console.log("at least " + numNewMessages + " new messages");
    });
    /*oldChats = this.state.oldChats;
    // Move those old messages to the oldChats array
    this.state.liveChats.reduceRight((_, item) => {
      if (numNewMessages == 0) break;
      oldChats.push(item);
      numNewMessages--;
    });*/

    var newOldChats = this.state.liveChats.slice(this.state.liveChats.length - numNewMessages, this.state.liveChats.length);
    var oldChats = newOldChats.concat(this.state.oldChats);
    // Used in pagination

    console.log("Setting chats");
    console.log(messages);
    this.setState({
      liveChats: messages,
      oldChats: oldChats,
      messages: messages.concat(oldChats)
    });
  }

  onError(error) {
    console.error(error);
  }

  onLoadEarlier() {
    const chatsRef = collection(getFirestore(), "groups", this.props.groupId, "generalChat")
    const chatsQuery = query(chatsRef, orderBy("createdAt", "desc"), startAfter(this.state.lastVisible), limit(this.state.limit));
    if (this.state.lastVisible) getDocs(chatsQuery)
      .then((CollectionSnapshot) => {
        let lastDoc;
        const messages = CollectionSnapshot.docs.map(doc => {
          const firebaseData = doc.data();
          lastDoc = doc;
          const data = {
            _id: doc.id,
            firebaseDoc: doc.ref,
            text: '',
            createdAt: new Date().getTime(),
            ...firebaseData
          };
          if (!firebaseData.system) {
            data.user = {
              ...firebaseData.user
            };
          }

          return data;
        });
        this.setState({
          oldChats: this.state.oldChats.concat(messages),
          messages: this.state.messages.concat(messages),
          lastVisible: lastDoc
        })
        console.log("lastVisible now set to " + lastDoc.data());
      });
  }

  componentWillUnmount() {
    this.firestoreUnsubscribe();
  }

  handleSend(messages) {
    const text = messages[0].text;
    console.log(this.state.currentUser.displayName);

    const chatsRef = collection(getFirestore(), "groups", this.props.groupId, "generalChat")
    var newChat = {
      text,
      createdAt: new Date().getTime(),
      
      user: {
        _id: this.state.currentUser.uid,
        name: this.state.currentUser.displayName,
        // Some users have avatarUrl and some photoURL.
        // If you figure out why please lmk - Ryan
        avatar: this.state.currentUser.photoURL ? this.state.currentUser.photoURL : this.state.currentUser.avatarUrl
      }
    }
    if(this.state.imageURL) newChat.image = this.state.imageURL;
    addDoc(chatsRef, newChat);
    this.setState({
      imageURL: null,
      localImageURI: null
    })

    /*this.props.chatPath
      .set(
        {
          latestMessage: {
            text,
            createdAt: new Date().getTime()
          }
        },
        { merge: true }
      );*/
  }


  // getMore
  // get more starting at id of last entry to oldMessages array.
  // append to oldMessages array

  renderBubble(props) {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            backgroundColor: 'white'
          }
        }}
        textStyle={{
          right: {
            color: '#fff'
          }
        }}
      />
    );
  }

  renderLoading() {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size='large' color='#6646ee' />
      </View>
    );
  }

  renderSend(props) {
    return (
      <Send {...props}>
        <View style={styles.sendingContainer}>
          <Ionicons icon='send-circle' size={32} color='#6646ee' />
        </View>
      </Send>
    );
  }

  scrollToBottomComponent() {
    return (
      <View style={styles.bottomComponentContainer}>
        <Ionicons icon='chevron-double-down' size={36} color='#6646ee' />
      </View>
    );
  }

  renderSystemMessage(props) {
    return (
      <SystemMessage
        {...props}
        wrapperStyle={styles.systemMessageWrapper}
        textStyle={styles.systemMessageText}
      />
    );
  }

  renderActions(props) {
    return (
      <View style={{ padding: 8, flexDirection: "row" }}>
        <TouchableOpacity onPress={() => this.handlePickImage()}>
          <Ionicons name={'ios-images'} size={30} color={'#6646ee'} />
        </TouchableOpacity>
        {
          this.state.localImageURI &&
          <Image source={{ uri: this.state.localImageURI }} style={{ width: 50, height: 50 }} />
        }
        {
          this.state.loadingImage &&
          <ActivityIndicator size='large' />
        }
      </View>
    )
  }

  async handlePickImage() {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        allowsMultipleSelection: false
      });
      if (!result.cancelled) {
        //this.setState({ image: result.uri });
        //console.log(this.props.chatPath)
        //console.log("PHOTO URI:", result.uri);
        this.setState({
          loadingImage: true
        })
        let extension = result.uri.split('.').pop();
        let storRef = ref(getStorage(), "groups/" + this.props.groupId + "/generalChat/" + uuid() + extension);
        //console.log(this.props.chatPath.path + uuid() + extension);
        console.log("MADE IT 1: ", result.uri);
        await uploadString(storRef, result.uri, "data_url");
        let url = await getDownloadURL(storRef);
        //console.log(url);
        this.setState({
          imageURL: url,
          localImageURI: result.uri,
          loadingImage: false,
        })
        return url;
      }

      console.log(result);
    } catch (E) {
      console.log(E);
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <GiftedChat
          messages={this.state.messages}
          listViewProps={{ bounces: this.props.bounces, nestedScrollEnabled: true }}
          onSend={messages => this.handleSend(messages)}
          user={{ _id: this.state.currentUser.uid }}
          style={{ height: 5 }}
          //renderChatFooter={() => <Ionicons style={{}} name={'ios-heart'} size={20} color={'#6646ee'} /> }
          placeholder="Make someone's day..."
          /*renderChatFooter*/
          renderTicks={(currentMessage) => (
            <View style={{ flexGrow: 1, minWidth: 30 , alignSelf: "stretch", alignItems: 'flex-end', }}>
              {/*console.log("THE USSSSERRR:", currentMessage)*/}
              {<LikeButton style={{}} currentMessage={currentMessage}/>}
              {/*<Ionicons style={{ bottom: 5}} name={'ios-heart'} size={20} color={'#6646ee'} />*/}
            </View>
          )}
          //renderBubble={() => <Text>BUBBLE</Text>}
          /*renderCustomView = {({currentMessage}) => (
            <View style={{ height: 0, alignItems: 'flex-end', paddingRight: 10, paddingBottom: 5 }}>
              {console.log("THE USSSSERRR:", currentMessage)}
              <Ionicons style={{}} name={'ios-heart'} size={20} color={'#6646ee'} />
            </View>
          )}*/
          isCustomViewBottom
          alwaysShowSend
          showUserAvatar
          scrollToBottom
          loadEarlier
          infiniteScroll
          renderUsernameOnMessage={true}
          onLoadEarlier={this.onLoadEarlier.bind(this)}
          renderBubble={this.renderBubble}
          renderActions={this.renderActions.bind(this)}
          renderLoading={this.renderLoading}
          textInputProps={{
            onFocus: this.props.onFocus ? () => this.props.onFocus() : null
          }}
          /*renderSend={this.renderSend}*/
          scrollToBottomComponent={this.scrollToBottomComponent}
          renderSystemMessage={this.renderSystemMessage}
          isKeyboardInternallyHandled={false}
        />
        {
          this.props.shouldAvoidKeyboard &&
          <KeyboardAvoidingView behavior={'padding'} keyboardVerticalOffset={80} />
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  sendingContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottomComponentContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  systemMessageWrapper: {
    backgroundColor: '#6646ee',
    borderRadius: 4,
    padding: 5
  },
  systemMessageText: {
    fontSize: 14,
    color: '#fff',
    fontWeight: 'bold'
  }
});