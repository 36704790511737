import React from "react";
import {
  Text,
  View,
  Dimensions,
  Image,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Swiper from "react-native-web-swiper";

import HavingFun from "./OnboardingSvgs/HavingFun";
import Studying from "./OnboardingSvgs/Studying";
import NoteList from "./OnboardingSvgs/NoteList";
import Hero from "./OnboardingSvgs/Hero"
import { GroupListModal } from "./groups/GroupListModal";

const { height, width } = Dimensions.get("window");

const ReLearnLogo = () => {
  return (
    <View style={styles.container}>
      <LinearGradient
        style={styles.gradientView}
        colors={["#FF512F", "#DD2476"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <View style={{alignContent: "center", justifyContent: "space-around"}}>
          <Text
            style={{
              textAlign: "center",
              fontSize: 40,
              fontWeight: "500",
              color: "white",
            }}
          >
            Welcome to
          </Text>
          
          <View style={{}}>
          <Image
            style={{
              height: 100,
              width: 250,
              alignSelf: "center"
            }}
            source={require("../assets/images/relearnText.png")}
            resizeMode="contain"
          />
          </View>
        </View>

        <Text style={styles.firstDescription}>
          With ReLearn, your class has one seamless place to collect and share
          info, study together, and stay connected.{" "}
        </Text>
      </LinearGradient>
    </View>
  );
};

const NoInvites = ({sideBySide}) => {
  return (
    <View style={styles.container}>
      <LinearGradient
        style={sideBySide ? styles.gradientLTR : styles.gradientView}
        colors={["#0393b0", "#1c1590"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <View style={styles.svg}>
          <View style={sideBySide ? {paddingLeft: 10} : {}}>
          <HavingFun />
          </View>
        </View>

        <View style={styles.info}>
        <Text style={styles.title}>No More Asking for Invites</Text>

        <Text style={styles.description}>
          All you need to join your class ReLearn Groups is your school email
          and course numbers. College learning should be a social experience no
          matter what classes your friends are in.
        </Text>
        </View>
      </LinearGradient>
    </View>
  );
};

const StudySessions = ({sideBySide}) => {
  return (
    <View style={styles.container}>
      <LinearGradient
        style={sideBySide ? styles.gradientRTL : styles.gradientView}
        colors={["#11c22b", "#00532b"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <View style={styles.svg}>
          <Studying  />
        </View>

        <View style={styles.info}>
        <Text style={styles.title}>Study Sessions Any Time, Anywhere</Text>

        <Text style={styles.description}>
          Start impromptu study sessions or just hang out with classmates with
          similar interests in ReLearn's Group Video Rooms. Cramming for an exam
          at 3am has never been this fun.
        </Text>
        </View>
      </LinearGradient>
    </View>
  );
};

const ReLearnCards = ({sideBySide}) => {
  return (
    <View style={styles.container}>
      <LinearGradient
        style={sideBySide ? styles.gradientLTR : styles.gradientView}
        colors={["#e3e00e", "#d68d06"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <View style={styles.svg}>
          <NoteList  />
        </View>
        
        <View style={styles.info}>
        <Text style={styles.title}>
          All the Info in One Place
        </Text>

        <Text style={styles.description}>
          ReLearn Cards is a powerful self-organizing knowledge-base you can
          build with your classmates throughout the semester. Glanceable like 
          flash-cards, but on-topic and in-depth like a textbook.
        </Text>
        </View>
      </LinearGradient>
    </View>
  );
};

const ReLearnHeroes = ({sideBySide}) => {
  return (
    <View style={styles.container}>
      <LinearGradient
        style={sideBySide ? styles.gradientRTL : styles.gradientView}
        colors={["#c001c6", "#7c0ee3"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <View style={styles.svg}>
          <Hero  />
        </View>

        <View style={styles.info}>
        <Text style={styles.title}>
          ReLearn Heroes
        </Text>

        <Text style={styles.description}>
          Every Card you make and like you get increases your Score.
          ReLearn Heroes are your community's greatest contributors. Heroes will
          soon get access to free merch at events, badges, an exclusive group,
          and more.
        </Text>
        </View>
      </LinearGradient>
    </View>
  );
};

export const BigScreenOnboarding = () => {
  return (
    <ScrollView>
      <ReLearnLogo />
      <NoInvites sideBySide={true}/>
      <StudySessions sideBySide={true}/>
      <ReLearnCards sideBySide={true}/>
      <ReLearnHeroes sideBySide={true}/>
    </ScrollView>
  );
};

export const SmallScreenOnboarding = () => {
  return (
    <View style={{ flex: 1 }}>
      <Swiper slideWrapperStyle={{ paddingBottom: 20 }}>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ReLearnLogo />
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <NoInvites />
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <StudySessions />
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ReLearnCards />
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ReLearnHeroes />
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <GroupListModal isVisible={true} viewOnly={true} />
        </View>
      </Swiper>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 30,
    overflow: "hidden",
    margin: 20,
    alignContent: "center",
    flex: 0.95,
  },
  gradientView: {
    justifyContent: "space-between",
    padding: 40,
    alignContent: "center",
    flex: 1,
  },
  gradientLTR: {
    justifyContent: "space-between",
    padding: 40,
    alignContent: "center",
    flex: 1,
    flexDirection: "row",
  },
  gradientRTL: {
    justifyContent: "space-between",
    padding: 40,
    alignContent: "center",
    flex: 1,
    flexDirection: "row-reverse",
  },
  svg: {
    alignSelf: "center",
    flex: 1,
    maxWidth: 500,
    justifyContent: "center",
    alignContent: "center",
    flexShrink: 2,
    aspectRatio: 1,
  },
  info: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    flexGrow: 2,
    maxWidth: 300,
  },
  title: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 20,
    textAlign: "center",
    alignSelf: "center",
    fontSize: 28,
    fontWeight: "500",
    color: "white",
    maxWidth: 600,
  },
  description: {
    textAlign: "center",
    alignSelf: "center",
    fontSize: 22,
    marginHorizontal: 20,
    fontWeight: "400",
    color: "white",
    maxWidth: 600,
  },
  firstDescription: {
    textAlign: "center",
    alignSelf: "center",
    fontSize: 30,
    marginHorizontal: 20,
    fontWeight: "400",
    color: "white",
    maxWidth: 600,
  },
});
